import technology from '../images/sectors/technology.jpg';
import chemical from '../images/sectors/chemicals.jpg'
import healthcare from '../images/sectors/healthcare.jpg';
import industrial from '../images/sectors/industrial.jpg';
import retail from '../images/sectors/retail.jpg';
import renewable from '../images/sectors/renewable.jpg';
import infrastructure from '../images/sectors/infrastructure.jpg';
import banking from '../images/sectors/banking.jpg';
import energy from '../images/sectors/energy.jpg';
import hospitality from '../images/sectors/hospitality.jpg';


export const sectors = [
    {
        id: 1,
        img: technology,
        name: 'Technology & Business Services',
    },
    {
        id: 1,
        img: chemical,
        name: 'Chemicals',
    },
    {
        id: 1,
        img: healthcare,
        name: 'Pharma & Healthcare',
    },
    {
        id: 1,
        img: industrial,
        name: 'Industrials',
    },
    {
        id: 1,
        img: retail,
        name: 'Retail & Consumer',
    },
    {
        id: 1,
        img: renewable,
        name: 'Renewable Energy',
    },
    {
        id: 1,
        img: infrastructure,
        name: 'Infrastructure & Real Estate',
    },
    {
        id: 1,
        img: banking,
        name: 'Banking & Financial Services',
    },
    {
        id: 1,
        img: energy,
        name: 'Energy & Utilities',
    },
    {
        id: 1,
        img: hospitality,
        name: 'Hospitality & Leisure',
    },
]
import React from "react";
import PageHeading from "../components/PageHeading";
import { useParams } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { ImageUrl } from "../url";

const ResourceData = () => {
  const { id } = useParams();
  console.log(id, "ids");

  const resource = useSelector((state) =>
    state.resource.resources.find((resource) => resource._id === id)
  );

  console.log(resource, "resource data");

  return (
    <>
      <section className="resource-page-section page-header">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <PageHeading heading={"Useful Resources"} subheading={`${resource?.resourceName}`} />
            </div>
          </div>
        </div>
      </section>

      <section className="resourcedata py-5 my-1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-12">
              <div className="resource-all-data">
                <div className="res-img-div mb-3">
                  <img
                    src={`${ImageUrl}/resources/` + resource?.mainImg[0]?.filename}
                    className="img-fluid"
                    alt={resource?.resourceName}
                  />
                </div>
                <h3 className="resource-name">{resource?.resourceName}</h3>
                <div
                dangerouslySetInnerHTML={{ __html: resource?.content }}
              ></div>
              </div>

            
            </div>
            {/* <div className="col-lg-3 col-md-12 col-12">Hello</div> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default ResourceData;

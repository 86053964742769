import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageHeading from "../components/PageHeading";
import NoData from "../components/NoData";
import { FiSettings } from "react-icons/fi";
import { BiSearch } from "react-icons/bi";
import Backdrop from "../components/Backdrop/Backdrop";
import { getResources } from "../apiCalls/resource";
import BlogLists from '../components/Blog/BlogLists'

const Resources = () => {
  const categoryRef = useRef(null);
  const dispatch = useDispatch();

  const resourceList = useSelector((state) => state.resource.resources);

  // console.log(resourceList, "resourceList");

  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const [blogs, setBlogs] = useState(resourceList);
  const [searchTerm, setSearchTerm] = useState();

  useEffect(() => {
    getResources(dispatch);
  }, [dispatch]);

  const showHideFilter = () => {
    categoryRef.current.classList.toggle("active");
    setDrawerIsOpen(true);
  };

  const HideFilter = () => {
    categoryRef.current.classList.toggle("active");
    setDrawerIsOpen(false);
  };

  const closeDrawerHandler = () => {
    showHideFilter();
    setDrawerIsOpen(false);
  };

  const searchservice = (e) => {
    setSearchTerm(e.target.value);
    console.log(searchTerm);
    //   setBlogs(
    //     bloglist.filter((val) => val.name.toLowerCase().includes(e.target.value.toLowerCase()))
    //   );
  };

  // const filterItem = (catItem) => {
  //   console.log("true");
  //   const updatedItem = bloglist.filter((curelem) => {
  //     return curelem.websitType === catItem;
  //   });
  //   setBlogs(updatedItem);
  // };

  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <section className="resource-page-section page-header">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <PageHeading
                heading={"Useful Resources"}
                subheading={"Resources"}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="services_page_wrapper mt-5">
        <div className="container">
          <div className="row">
            {/* <div className="col-lg-3 col-md-12 col-12">
              <div className="category-filter-icon mb-3">
                <div className="search-box">
                  <input
                    type="text"
                    placeholder="Search Resources"
                    onChange={searchservice}
                  />
                  <BiSearch />
                </div>
              </div>
              <div className="catalog__filter mb-4" ref={categoryRef}>
                <div className="search-box desktop-device">
                  <input
                    type="text"
                    placeholder="Search resources"
                    onChange={searchservice}
                  />
                  <BiSearch />
                </div>
              </div>
            </div> */}

            {blogs.length > 0 ? (
              <BlogLists blogs={blogs} />
            ) : (
              <>
                <div className="col-9 text-center">
                  <NoData />
                </div>
              </>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Resources;

export const navbarList = [
  // {
  //   id: 1,
  //   catname: "Clients",
  //   subcategory: [
  //     {
  //       subcatname: "Clients",
  //       url: '/clients',
  //     },
  //     {
  //       subcatname: "Investor Relations",
  //       url: '/investor-relations',
  //     },
  //   ],
  // },
    {
      id: 1,
      catname: "Team",
      subcategory: [
        {
          subcatname: "Leadership",
          url: '/teams',
        },
        {
          subcatname: "Career",
          url: '/career',
        },
      ],
    },
    {
      id: 2,
      catname: "X-Club",
      subcategory: [
        {
          subcatname: "Resources",
          url: '/resources',
        },
        {
          subcatname: "Join Us",
          url: '/x-club',
        },
      ],
    },
  ];
  
import React from "react";
import PageHeading from "../components/PageHeading";

const PrivacyPolicy = () => {
  return (
    <>
      <section className="privacy-page-section page-header">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <PageHeading
                heading={"Privacy Policy"}
                subheading={"Privacy Policy"}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="privacy-content py-5">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="priv-box">
                <p>
                  This Privacy Policy is designed to inform you about our
                  practices regarding collection, use, and disclosure of
                  information that you may provide via this Website. Please be
                  sure to read this entire Privacy Policy before using or
                  submitting information to this Website. Use of this Website
                  constitutes your consent to our Privacy Policy. X-B4 Advisory
                  LLP (“X-B4”) respects your privacy on the Internet. This
                  Website collects certain types of information about our
                  contacts and strictly maintains the privacy of the information
                  so collected. We also maintain a certain transparency on what
                  we do with it and how to correct or change your information.
                </p>
                <h3>Personal Information and its Usage</h3>
                <p>
                  Like many other websites, this Website collects information
                  from its visitors/customers by permitting you to communicate
                  directly with us via e-mail and feedback / contact us forms.
                  Certain information collected from your end may be mandatory
                  whereas certain information may be optional.
                </p>
                <h3>We collect personal information for following purposes:</h3>
                <ol>
                  <li>
                    to provide information about our products and services;
                  </li>
                  <li>
                    to seek your feedback or to contact you in relation to the
                    products and services offered;
                  </li>
                  <li>to process orders or applications submitted by you;</li>
                  <li>
                    to administer or otherwise carry out our obligations in
                    relation to any agreement you have with us;
                  </li>
                  <li>
                    to anticipate and resolve problems with any goods or
                    services supplied/rendered to you;
                  </li>
                  <li>
                    to create products or services that may meet your needs;
                  </li>
                  <li>
                    to process and respond to requests, improve our operations,
                    and communicate with visitors about our products, services
                    and businesses;
                  </li>
                  <li>to allow you to subscribe to our newsletter.</li>
                </ol>
                <h3>
                  We may disclose your personal identifiable information to
                  third parties, located in India and/or any other country, but
                  only for following purposes:
                </h3>
                <ol>
                  <li>
                    to contractors we use to support our business (e.g.
                    fulfillment services, technical support, delivery services,
                    financial institutions, etc.), in which case we will require
                    such third parties to agree to treat it in accordance with
                    this Privacy Policy;
                  </li>
                  <li>
                    in connection with the sale, assignment, or other transfer
                    of the business of this Website to which the information
                    relates, in which case we will require any such buyer to
                    agree to treat it in accordance with this Privacy Policy; or
                  </li>
                  <li>
                    where required by applicable laws, court orders, or
                    government regulations.
                  </li>
                </ol>
                <p>
                  In addition, X-B4 or its agents and/or sub-contractors may use
                  your personal information to contact you for promotion of
                  products/schemes etc. of X-B4 or of its partners from time to
                  time. You hereby agree and understand that such promotional
                  calls shall be made to you unless you specifically convey X-B4
                  not to call you further for such promotions, schemes etc.
                </p>
                <h3>Securing Your Data</h3>
                <p>
                  X-B4 will take reasonable technical and organizational
                  precautions to prevent the loss, misuse or alteration of your
                  personal information. X-B4 will store all the personal
                  information you provide on its secure servers.
                </p>
                <h3>Updating this statement</h3>
                <p>
                  X-B4 may update this privacy policy by posting a new version
                  on this Website. You should check this page occasionally to
                  ensure you are familiar with any changes.
                </p>
                <h3>Contact Us</h3>
                <p>
                  If you have any questions, comments, or concerns about this
                  Privacy Policy or the information practices of this Website,
                  please write to us at info@x-b4.com.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PrivacyPolicy;

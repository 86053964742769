import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import mumbai from "../assets/images/globals/mumbai2.jpg";
import india from "../assets/images/globals/india.jpg";
import delhi from "../assets/images/globals/delhi.jpg";
import london from "../assets/images/globals/london.jpg";
import singapore from "../assets/images/globals/singapore.jpg";
import dubai from "../assets/images/globals/dubai.jpg";

const GlobalPres = () => {
  const settings = {
    dots: false,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <section className="globals">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="sec-heading">
                <h2>Global Presence</h2>
                <p className="mt-3">
                  Uniquely positioned as a global investment bank with a strong
                  network and presence across key financial markets worldwide.
                  Our global connectivity enables us to offer seamless financial
                  solutions and strategic insights tailored to meet the diverse
                  needs of clients across industries and geographies.
                </p>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-12">
              <Slider {...settings}>
                <div className="row mb-4 img-box">
                  <Link to="/" className="img-link">
                    <img src={delhi} className="img-fluid" loading="lazy" />
                    <figcaption class="img_caption">Delhi</figcaption>
                  </Link>
                </div>
                <div className="row mb-4 img-box">
                  <Link to="/" className="img-link">
                    <img src={mumbai} className="img-fluid" loading="lazy" />
                    <figcaption class="img_caption">Mumbai</figcaption>
                  </Link>
                </div>{" "}
                <div className="row mb-4 img-box">
                  <Link to="/" className="img-link">
                    <img src={london} className="img-fluid" loading="lazy" />
                    <figcaption class="img_caption">London</figcaption>
                  </Link>
                </div>{" "}
                <div className="row mb-4 img-box">
                  <Link to="/" className="img-link">
                    <img src={dubai} className="img-fluid" loading="lazy" />
                    <figcaption class="img_caption">Dubai</figcaption>
                  </Link>
                </div>{" "}
                <div className="row mb-4 img-box">
                  <Link to="/" className="img-link">
                    <img src={singapore} className="img-fluid" loading="lazy" />
                    <figcaption class="img_caption">Singapore</figcaption>
                  </Link>
                </div>{" "}
             
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default GlobalPres;

import React from "react";
import { Link } from "react-router-dom";

const TrustContent = () => {
  return (
    <>
      <section className="trust-sec">
        <div className="container-fluid">
          <div className="row">
            <div className="trust-bg-img"></div>
            <div className="col-lg-7 offset-lg-5">
              <div className="trust-content">
                <h2>Trust & Legacy</h2>
                <p>
                  Welcome to X-B4, where we are dedicated to
                  "Elevating expectations, Redefining possibilities" in the realm of global investment banking.
                  As your trusted house banker, we offer a range of
                  comprehensive financial solutions tailored to meet your unique
                  needs and goals.
                </p>
                <p>
                  At X-B4, we're more than just bankers – we're partners in your
                  journey to success. Whether you're navigating private
                  ventures, preparing for public offerings, or seeking expert
                  transaction structuring and advisory, we're here to guide you
                  through every stage of your financial life cycle and unlock
                  value along the way.
                </p>
                <button className="adv-btn">
                  <Link to="/about">Read More </Link>
                  
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TrustContent;

import React from "react";
import BlogItem from "./BlogItem";

const BlogLists = ({ blogs }) => {

  console.log(blogs, 'blogs data')
  return (
    <div className="col-lg-12 col-12">
      <div className="row mb-4">
        {blogs.map((service) => (
          <BlogItem service={service} /> 
        ))}
      </div>
    </div>
  );
};

export default BlogLists;

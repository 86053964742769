import React from "react";
import Banner from "../components/Banner/Banner";
import TrustContent from "../components/TrustComp/TrustContent";
import Advantages from "../components/Advantages";
import ServiceComp from "../components/ServiceComp";
import GlobalPres from "../components/GlobalPres";
import Team from "../components/Team";
import Testimonial from "../components/Testimonial";
import Clients from "../components/Clients";
// import Subscription from "../components/Subscription";
import Numbers from "../components/Numbers";
import ClientComp from "../components/ClientComp";
import XComp from "../components/XComp";
import Sectors from "../components/Sectors";
import Investors from "../components/Investors";

const Home = () => {
  return (
    <>
      <Banner />
      <Advantages />
      <TrustContent />
      <ServiceComp />
      <Numbers />
      <GlobalPres />
      <Team home={true}/>
      <Sectors total={4} btn={true}/>
      {/* <Clients /> */}
      {/* <ClientComp /> */}
      {/* <Investors total={4} btn={true}/> */}
      <Testimonial />
      <XComp />
      {/* <Subscription /> */}
    </>
  );
};

export default Home;

import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { slides } from "../assets/data/testimonials";

const Testimonial = () => {
  let settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <section className="testimonial">
        <div className="container">
        <div className="row mb-3">
            <div className="col-12">
              <div className="sec-heading">
                <h2>key testimonials</h2>
                <p>What they say about us, ear it from our clients.</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 ml-auto mr-auto">
              <div className="testimonial-active nav-style-1 nav-testi-style ">
                <Slider {...settings}>
                  {slides.map((slide, index) => {
                    return (
                      <div className="swiper-slide" key={index}>
                        <div className="single-testimonial">
                          <div className="testimonial-img">
                            <img
                              className="img-fluid"
                              src={slide.image}
                              alt={slide.alt}
                              loading="lazy"
                            />
                          </div>

                          <div className="testimonial-content">
                           
                            <div className="client-info">
                              <h5>{slide.name}</h5>
                              {/* <p>{slide.city}</p> */}
                               <p>{slide.testimonial}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Testimonial;

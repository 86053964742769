import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router";
import ServiceList from "../components/ServiceList";
import { services } from "../assets/data/services";
import PageHeading from "../components/PageHeading";
import { FiSettings } from "react-icons/fi";
import AOS from "aos";
import Backdrop from "../components/Backdrop/Backdrop";
import PDF from "../components/Pdf";
import Team from "../components/Team";
import ClientComp from "../components/ClientComp";
import { publicRequest } from "../url";
import BlogLists from "../components/Blog/BlogLists";

const ServiceDetail = () => {
  const { url } = useParams();
  console.log(url, "urlll");
  const categoryRef = useRef(null);
  const [serviceResource, setServiceResource] = useState([]);

  const mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    if (mounted.current) {
      AOS.init({
        duration: 50
      });
      AOS.refresh();
    }
    return () => (mounted.current = false);
  }, []);

  console.log(url, "url");

  const [service, setService] = useState(null);
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);

  const showHideFilter = () => {
    categoryRef.current.classList.toggle("active");
    setDrawerIsOpen(true);
  };
  const closeDrawerHandler = () => {
    showHideFilter();
    setDrawerIsOpen(false);
  };

  const getResources = async () => {
    const res = await publicRequest.get(`/resources/?type=${url}`);

    console.log(res, "res data from query");
    if (res.status === 200) {
      setServiceResource(res.data);
    }
  };

  useEffect(() => {
  

    let service = services.find((service) => service.url === url);
    console.log(service, "service service");
    if (service) {
      setService(service);
    }
    getResources();
  }, [service]);

  console.log(service, "service");

  return (
    <>
      <section className="team-page-section page-header">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <PageHeading heading={service?.name} subheading={service?.name} />
            </div>
          </div>
        </div>
      </section>

      <section className="servicepage2_wrapper">
        <div className="container">
          <div className="row py-5">
            <div className="col-lg-3 col-md-12 col-12 service-small-list">
              <div className="category-filter-icon mb-3">
                <div className="filter-slide" onClick={() => showHideFilter()}>
                  <FiSettings className="filter-icon" />
                </div>
              </div>
              <div className="service-mobile-list mb-4" ref={categoryRef}>
                {drawerIsOpen && <Backdrop onClick={closeDrawerHandler} />}
                <ServiceList />
              </div>
            </div>
            <div className="col-lg-9 col-md-12 col-12 content-div">
              <div className="servicepage-img">
                <img
                  src={service?.featuredImg}
                  alt={service?.name}
                  className="img-fluid"
                  loading="lazy"
                />
              </div>
              <div className="servicepage-name">
                <h4 className="heading">{service?.name}</h4>
              </div>
              <div className="row content-row">
                <div className="col-12">
                  <p className="desc"> {service?.desc}</p>
                </div>
              </div>
              <div className="service-list-data features-list">
                {service?.features ? (
                  <>
                    {/* <h3 className="mt-3 heading">More Info...</h3> */}
                    {service?.features?.map((item, index) => {
                      console.log(item, "item");
                      return (
                        <div className="feature-box">
                          <div className="nos">
                            <h2>{index + 1}</h2>
                          </div>
                          <div className="content">
                            <h3>{item?.heading}</h3>
                            <p className="content-text">
                            <div dangerouslySetInnerHTML={{__html: item?.desc}}>
                            </div>
                            </p>
                            {/* <p> {item?.desc}</p> */}
                          </div>
                        </div>
                      );
                      // return <li key={index}>{item}</li>;
                    })}
                  </>
                ) : (
                  ""
                )}
              </div>

              <div className="service-list-data advantages-list mt-5">
                {service?.advantages ? (
                  <>
                    <h3 className="heading">Our Advantages</h3>
                    {service?.advantages?.map((item, index) => {
                      return (
                        <div className="adv-box">
                          <p>{item}</p>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  ""
                )}
              </div>

              <div className="row file-row">
                <div className="content">
                  <h5>Download service booklet</h5>
                </div>

                <div className="file">
                  <PDF />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Team cate={url}/> 
      {/* <ClientComp /> */}
      <section className="services_page_wrapper service-resource-sec mt-5">
        <div className="container">
        <div className="row mb-5">
            <div className="col-12">
              <div className="sec-heading">
                <h2>Useful Resources</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <BlogLists blogs={serviceResource?.slice(0,3)} />
          </div>
        </div>
      </section>
    </>
  );
};

export default ServiceDetail;

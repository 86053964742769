import React from "react";
import { GoArrowRight } from "react-icons/go";
import logo from "../../assets/images/banner/bannerimg.png";
import { IoArrowForward } from "react-icons/io5";
import { Link } from "react-router-dom";

const Banner = () => {
  return (
    <>
      <section className="banner mt-07">
        {/* <video src={video} loop autoPlay muted /> */}
        <div className="container banner-container">
          <div className="row align-items-center">
            <div className="col--lg-g col-md-6 col-12 banner-left">
              <h2 className="banner-heading">Leading the Future of Financial Advisory</h2>
              <p className="banner-text">
                At X-B4, we bridge the gap between visionary leaders and their
                financial aspirations. Our bespoke solutions and expert guidance
                help you navigate complex financial strategies, secure growth
                capital, and achieve market readiness.
              </p>

              <div className="btn-div">
                <Link to="/contact" className="banner-btn">
                  Get Started with X-B4{" "}
                  <IoArrowForward className="banner-btn-icon" />
                </Link>
              </div>
            </div>
            <div className="col--lg-g col-md-6 col-12 banner-right">
              <div className="banner-img-box">
                <img src={logo} className="img-fluid"  alt="banner img"/>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner;

import React from "react";
import PageHeading from "../components/PageHeading";
import Timeline from "../components/Timeline";
import mission from "../assets/images/about/mission.jpg";
import vision from "../assets/images/about/vision.jpg";

const About = () => {
  return (
    <>
      <section className="about-page team-page-section page-header">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <PageHeading heading={"About Us"} subheading={"About"} />
            </div>
          </div>
        </div>
      </section>

      <section className="about-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-5 col-12">
              <div className="sec-content">
                {/* <h2>Mission</h2> */}
                <h3>ELEVATING EXPECTATIONS. REDEFINING POSSIBILITIES.</h3>
                <p>
                X-B4 is where ambition meets action. Our mission is to elevate expectations and redefine the possibilities of what boutique banking can achieve. With unwavering dedication, we empower our clients to shape a future that surpasses even their wildest dreams.
                </p>
              </div>
            </div>
            <div className="col-lg-7 col-md-7 col-12">
              <div className="sec-img">
                <img src={mission} className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="vision-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-12">
              <div className="sec-img">
                <img src={vision} className="img-fluid" />
              </div>
            </div>
            <div className="col-lg-5 col-12">
              <div className="sec-content">
                {/* <h2>Our Vision</h2> */}
                <h3>REDEFINING FINANCIAL EXCELLENCE</h3>
                <p>
                To redefine financial advisory through unmatched expertise and personalized service. At X-B4, our vision is to set new standards in the industry by providing bespoke financial solutions that meet the unique needs of each client."
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <Timeline /> */}

      {/* <section className="state-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-12">Statement</div>
          </div>
        </div>
      </section> */}

      <section className="timeline-sec">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <Timeline />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;

import React, { useState, useEffect, useRef } from "react";
import AOS from "aos";
import { BsCardList } from "react-icons/bs";
import { useParams } from "react-router";

// import { servicelist } from "../../assets/data/ServicesData";
import { useHistory } from "react-router-dom";
import { RiSecurePaymentLine } from "react-icons/ri";
import { GoGraph } from "react-icons/go";
import { MdCorporateFare } from "react-icons/md";
import { FaUsersViewfinder } from "react-icons/fa6";
import { RiFundsFill } from "react-icons/ri";

const ServiceList = () => {

  return (
    <>
      <ul className="service-menu-items">
        
        <li className="service-menu-item" data-aos="fade-right">
          <a exact href="/services" className="nav-link">
            <span className="icon">
              <BsCardList />
            </span>
            <span>All Services</span>
          </a>
        </li>

        <li className="service-menu-item" data-aos="fade-right">
          <a exact href="/services/corporate-fundraising-advisory" className="nav-link">
            <span className="icon">
              <RiFundsFill />
            </span>
            <span>Corporate Fundraising Advisory</span>
          </a>
        </li>
        <li className="service-menu-item" data-aos="fade-right">
          <a exact href="/services/transaction-advisory" className="nav-link">
            <span className="icon">
              <RiSecurePaymentLine />
            </span>
            <span>Transaction Advisory</span>
          </a>
        </li>
        <li className="service-menu-item" data-aos="fade-right">
          <a exact href="/services/capital-market-solutions" className="nav-link">
            <span className="icon">
              <GoGraph />
            </span>
            <span>Capital Market Solutions</span>
          </a>
        </li>
        <li className="service-menu-item" data-aos="fade-right">
          <a exact href="/services/transaction-structuring" className="nav-link">
            <span className="icon">
              <MdCorporateFare />
            </span>
            <span>Transaction Structuring</span>
          </a>
        </li>
        
        <li className="service-menu-item" data-aos="fade-right">
          <a exact href="/services/debt-syndication-and-advisory" className="nav-link">
            <span className="icon">
              <FaUsersViewfinder />
            </span>
            <span>Debt Syndication & Advisory</span>
          </a>
        </li>
      </ul>
    </>
  );
};

export default ServiceList;

import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { getClients } from "../apiCalls/client";
import { ImageUrl } from "../url";
import { Link } from "react-router-dom";

const Investors = ({total, btn}) => {
    const dispatch = useDispatch();

    const clientList = useSelector((state) => state.client.clients);
  
    console.log(clientList, "clientList");
  
    useEffect(() => {
      getClients(dispatch);
    }, [dispatch]);
  return (
    <>
    <section className="team-member investor-list">
        <div className="container">
          <div className="row mb-3">
            <div className="col-12">
              <div className="sec-heading">
                <h2>Our clientele</h2>
                <p>
                Strengthening connections with investors through transparent communication and strategic engagement. We ensure that your public company's value and performance are effectively communicated to stakeholders.
                </p>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            {clientList.slice(0,total).map((data, index) => {
              return (
                <div className="col-lg-3 col-md-4 col-sm-6 col-12 my-2">
                  <div className="investor-box">
                    <Link to={`/investor-relations/${data._id}`}>
                      <div className="img-box">
                        <img
                          src={
                            `${ImageUrl}/investors/` +
                            data?.mainImg[0]?.filename
                          }
                          alt=""
                          className="img-fluid"
                          loading="lazy"
                        />
                      </div>
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
          {
            btn ? (
                <div className="row mt-5">
                <div className="col-12">
                    <div className="team-btn">
                        <button><Link to="/investor-relations">Explore more</Link></button>
                    </div>
                </div>
              </div>
            ) : (
                ''
            )
          }
       
        </div>
      </section>
    </>
  )
}

export default Investors
import imageTwo from '../images/testimonial/foto-1.png';
import imgone from '../images/testimonial/img1.png';
import shail from '../images/testimonial/shailnegi.jfif';
import nirjhar from '../images/testimonial/nirjhar.jfif';

export const slides = [
    {
        name: 'Himanshu',
        testimonial: `X-B4 Advisory LLP played a crucial role in our recent fundraising round. Their expertise in capital raising, strategic investor targeting, and negotiation skills were instrumental in securing funding that aligned with our growth objectives. Highly recommend their fundraising services.`,
        city: 'Mother Sparsh',
        image: imgone,
        alt: 'Imageone',
    },
  
    {
        name: 'Shailendra Negi',
        testimonial: 'Thanks rahul and Vikrant. You people have been integral part of the team and the family. Your knowledge, commitment and way of bringing things in perspective and to finality - is commendable. Thanks X-B4 team for making it happen.',
        city: 'Lalor Park',
        image: shail,
        alt: 'ImageTwo',
    },   {
        name: 'Nirjhar Negi',
        testimonial: 'A big thank you to Rahul Kumar Gupta, Vikrant Singhi and the entire@xb4 team for all the effort and knowledge that you guys have shared. Please keep doing it in the very sincere way you do it. Kudos',
        city: 'Lalor Park',
        image: nirjhar,
        alt: 'ImageTwo',
    },
    // {
    //     name: 'Rahul Gupta',
    //     testimonial: 'Working with X-B4 Advisory LLP on our buy-side M&A deal was a seamless experience. Their attention to detail, strategic insights, and proactive approach ensured a successful transaction. We appreciate their dedication and expertise in navigating complex financial transactions." - Mark Davis, Managing Director, Acme Investments',
    //     city: 'South Cooggee',
    //     image: imgone,
    //     alt: 'Imagethree',
    // },
    // {
    //     name: 'Rasika Sawant',
    //     testimonial: 'We partnered with X-B4 Advisory LLP for our investor relations strategy, and they have consistently delivered exceptional results. Their in-depth market analysis, investor communication materials, and IR campaign management have strengthened our position in the market and enhanced shareholder value. A trusted partner for listed company investor relations.',
    //     city: 'Lalor Park',
    //     image: imageTwo,
    //     alt: 'ImageTwo',
    // },
]
import React, { useEffect, useState } from "react";
import { teams } from "../assets/data/team";
import { FaLinkedin, FaTwitterSquare } from "react-icons/fa";
import { Link } from "react-router-dom";

const Team = ({ home, cate }) => {
  console.log(cate, "cate");
  console.log(home, "home");

  const [teamList, setTeamList] = useState(teams);

  useEffect(() => {
    if (home) {
      setTeamList(teamList.filter((data) => data.home === true));
    } else {
      setTeamList(teamList.filter((member) => member.type && member.type.includes(cate)))

      // setTeamList(teamList.filter((data) => data.type === cate));
    }
  }, []);

  return (
    <>
      <section className="team-member">
        <div className="container">
          <div className="row mb-3">
            <div className="col-12">
              <div className="sec-heading">
                <h2>operating team</h2>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            {teamList.slice(0, 4).map((data, index) => {
              return (
                <div className="col-lg-3 col-md-4 col-sm-6 col-12 my-2">
                  <div className="team-box">
                    <div className="img-box">
                      <img src={data.img} alt="team" className="img-fluid" />
                    </div>
                    <div className="content-box">
                      <h3>
                        {data.name} <span>({data.post})</span>
                      </h3>
                      <p>{data.text}</p>

                      <p className="team-para">{data.about}</p>
                      <div className="social-box">
                        <a href={data?.link} target="_blank">
                          <FaLinkedin className="team-social-icon" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          {home && teamList.length > 3  ? (
            <div className="row mt-4">
              <div className="col-12">
                <div className="team-btn">
                  <button>
                    <Link to="/teams">Know More</Link>
                  </button>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </section>
    </>
  );
};

export default Team;

import React, { useEffect } from "react";
import PageHeading from "../components/PageHeading";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation, Link } from "react-router-dom";
import { getFiles } from "../apiCalls/files";
import { ImageUrl } from "../url";

const InvestorFiles = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const investUrl = location.pathname.split("/")[2];
  const { url } = useParams();
  // console.log(url, "url", investUrl);

  const filess = useSelector((state) => state.file.files);

  console.log(filess, "filess");

  useEffect(() => {
    getFiles(dispatch, url, investUrl);
  }, [dispatch]);

  return (
    <>
      <section className="investor-sec page-header">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <PageHeading
                heading={"Our Clientele"}
                subheading={url.toUpperCase()}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="team-member investor-type">
        <div className="container">
          <div className="row mb-3">
            <div className="col-12">
              <div className="sec-heading">
                <h2>{url.toUpperCase()}</h2>
              </div>
            </div>
          </div>
          <div className="row">
            {filess.map((data, index) => {
              return (
                <>
                  <div className="col-lg-4 col-md-6 col-12">
                    <div className="investfileName">
                      <Link
                        to={`${ImageUrl}/investors/` + data.reportFiles[0].filename}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {data.fileName}
                      </Link>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default InvestorFiles;

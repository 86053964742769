import React, { useEffect, useRef } from "react";
// import AboutImg from "../assets/images/driver.png";
import { Link } from "react-router-dom";
import { FaGlobeAsia, FaAward } from "react-icons/fa";
import { MdDashboard } from "react-icons/md";
import { GoGoal, GoArrowRight } from "react-icons/go";

import AOS from "aos";

const Advantages = () => {
  const mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    if (mounted.current) {
      AOS.init({
        duration: 50,
      });
      AOS.refresh();
    }
    return () => (mounted.current = false);
  }, []);
  return (
    <>
      <section className="about_wrapper pt-1">
        <div className="container py-5">
          <div className="row justify-content-between">
            <div className="col-md-4 col-sm-12 mb-4 mb-md-0">
              <h2 className="adv-heading">X-B4 Advantage</h2>
              <p className="sub-text">X-B4 has legacy driven experience of serving as a house banker to listed companies, large private institutions, family offices, HNIs, UHNIs etc across 20+ territories.</p>

              <button className="adv-btn"><Link to="/about">Read More </Link><GoArrowRight className="arrow-icon"/></button>
            </div>
            <div className="col-md-8 col-sm-12 text-md-start">
              <div className="row">
                <div className="col-lg-5 col-md-12 col-11 advantage-box">
                  <div className="box-icon">
                    <FaGlobeAsia className="adv-icon" />
                  </div>
                  <div className="box-content">
                    <h6>Global Reach</h6>
                    <p className="m-0">
                      With a network of partners and offices around the world,
                      we offer unparalleled access to global markets and
                      opportunities.
                    </p>
                  </div>
                </div>
                <div className="col-lg-5 col-md-12 col-11 advantage-box">
                  <div className="box-icon">
                    <FaAward className="adv-icon" />
                  </div>
                  <div className="box-content">
                    <h6>Expertise & Experience</h6>
                    <p className="m-0">
                    Our team of seasoned professionals brings a wealth of
                      experience and knowledge to every engagement, ensuring
                      optimal outcomes for our clients.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
              <div className="col-lg-5 col-md-12 col-11 advantage-box">
                  <div className="box-icon">
                    <MdDashboard className="adv-icon" />
                  </div>
                  <div className="box-content">
                    <h6>Tailored Solutions</h6>
                    <p className="m-0">
                    We understand that every client is unique. That's why we
                      offer customized solutions designed to address your
                      specific challenges and objectives.
                    </p>
                  </div>
                </div>
                <div className="col-lg-5 col-md-12 col-11 advantage-box">
                  <div className="box-icon">
                    <GoGoal className="adv-icon" />
                  </div>
                  <div className="box-content">
                    <h6>Commitment to Excellence</h6>
                    <p className="m-0">
                    At X-B4, excellence is not just a goal – it's our
                      standard. We are dedicated to delivering the highest
                      quality service and exceeding our clients' expectations.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Advantages;

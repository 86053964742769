import { createSlice } from "@reduxjs/toolkit";

export const resourceSlice = createSlice({
  name: "resource",
  initialState: {
    resources: [],
    isFetching: false,
    error: false,
    
  },
  reducers: {
    // get All
    getResourceStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    getResourceSuccess: (state, action) => {
      state.isFetching = false;
      state.resources = action.payload;
    },
    getResourceFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
  },
});

export const {
  getResourceStart,
  getResourceSuccess,
  getResourceFailure,
} = resourceSlice.actions;

export default resourceSlice.reducer;

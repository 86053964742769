import React from "react";
import PageHeading from "../components/PageHeading";

const TermsConditions = () => {
  return (
    <>
      <section className="privacy-page-section page-header">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <PageHeading
                heading={"Terms & Conditions"}
                subheading={"Terms & Conditions"}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="privacy-content py-5">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="priv-box">
                <p>
                  The following are the terms and conditions ("Terms of Use") on
                  which you may use the X-B4 website (the "Website") And/ or any
                  mobile application (“Application”) or any other digital
                  applications/platform x-b4.com (the "Website") and by
                  accessing the Website or the Application you agree to be bound
                  by them. The term x-b4 refers to x-b4, and its worldwide
                  affiliates. The Terms of Use shall apply to all services,
                  programs, information, and products that you may use or
                  otherwise access from time to time through the Website and/ or
                  Application. Please read the Terms of Use carefully. By
                  accessing the Application and / or Website and any pages
                  hereof, you are indicating that you have read, acknowledge and
                  agree to be bound by the Terms of Use. If you do not accept
                  these Terms of Use, then please do not use or access the
                  Application and / or Website or any pages thereof. Apart from
                  the Terms of Use, you also agree to be bound by other
                  Application and / or Website policies as may be available on
                  the Application and/ or Website.
                </p>
                <h3>Disclaimer</h3>
                <p>
                  By accessing the Application and / or website and/or using any
                  information or material provided on or via this Application
                  and /or website you agree to have read and be bound by this
                  Disclaimer.
                </p>
                <p>
                  The information and any material provided on this website or
                  Application or in any communication containing a link to this
                  website is not intended for distribution to, or use by, any
                  person or entity in any jurisdiction or country where such
                  distribution or use would be contrary to law or regulation or
                  which would subject Avendus to any registration requirement
                  within such jurisdiction or country. Neither the information,
                  nor any material or opinion contained in the Application or
                  website constitutes a solicitation or offer by X-B4 or its,
                  directors, and employees to buy or sell any securities,
                  futures, options or other financial instruments or provide any
                  investment advice or service. This information and any
                  material or opinion provided on the Application and/or website
                  is for your private information only. We do not represent that
                  the information and any material provided on the website or
                  Application is accurate or complete. X-B4 shall not have any
                  responsibility to maintain the website or Application, data
                  and services made available on the website or Application. No
                  part of any information or material may be copied or
                  duplicated in any form by any means, or redistributed. All
                  investments are subject to market risks.
                </p>
                <p>
                  In no event will X-B4 or its directors and employees be liable
                  for any damages including without limitation direct or
                  indirect, special, incidental, or consequential damages,
                  losses or expenses arising out of and in connection with the
                  website or Application or use thereof or inability to use
                  thereof, or in connection with any failure of performance,
                  error, omission, interruption, defect, delay in operation or
                  transmission, computer virus or system failure.
                </p>

                <p>
                  You acknowledge that you have read and understood the
                  Disclaimer statement and other legal information mentioned on
                  the website or Application and agree to abide by it.
                </p>

                <h3>Additional risk disclosure and disclaimers</h3>
                <p>
                  This information/data is provided by X-B4 on a strictly
                  confidential basis for exclusive use of the addressee. By
                  accepting this document, the recipient acknowledges and agrees
                  that it will not, and will cause its representatives,
                  affiliates and advisers not to, divulge any information
                  contained herein to another party without express prior
                  consent of X-B4. This document is not an official confirmation
                  of any transaction.
                </p>

                <p>
                  The valuation methodology, asset class and benchmark used in
                  this document have been derived from and are dependent upon
                  information obtained from third party agencies including but
                  not limited to fund managers, valuation agencies, credit
                  agencies, relevant stock exchanges and such other agencies as
                  may be identified by X-B4 from time to time for providing
                  valuation and related services. X-B4 is a mere aggregator of
                  such information and X-B4, its affiliates, directors, officers
                  and employees shall not be liable for any error, omission,
                  inaccuracies or delays in provision of the requisite
                  information from such third-party agencies. No representation
                  or warranty (expressed or implied) is made as to, the
                  fairness, accuracy, completeness or correctness of such
                  information or opinions contained herein and nothing contained
                  herein should be relied upon as a promise, representation or
                  indication of the future performance.
                </p>

                <p>
                  The prices, value, income and appreciation of the securities
                  mentioned are based on the information available in public or
                  latest information provided by the product manufacturers,
                  valuations agencies, rating agencies, stock exchanges etc. to
                  X-B4 and are subject to change. Investments in securities are
                  subject to risk including the risk of loss of the initial
                  principal amount invested. Past performance is not indicative
                  of future results. AWMPL acts merely as a distributor /
                  portfolio manager / investment advisor /facilitator and not a
                  custodian of these securities. Neither X-B4 nor any of its
                  affiliates has undertaken any regulatory, legal, tax or
                  accounting analysis relating to the suitability of the
                  securities.
                </p>

                <p>
                  The financial information contained in this report is not a
                  substitute for the statements you receive from the custodian
                  (s) of your accounts or third-party product manufacturers. You
                  are strongly encouraged to compare this report against the
                  actual account statements received from the custodian(s) your
                  accounts or third-party product manufacturers. If any
                  discrepancy exists between this report and your custodian 's
                  account statement or third-party product manufacturer's
                  statement, the qualified custodian's account statement or
                  third party product manufacturer's statement should be
                  considered to be the accurate document. However, please
                  contact your Relationship Manager if discrepancies are noted
                  or if you think you have not received your most current
                  account statement(s).
                </p>

                <p>
                  This document is for informational purposes only and does not
                  constitute an offer or invitation to purchase or subscribe to
                  any units, investment/stocks in any jurisdiction and no part
                  of it shall form the basis of or be relied upon in connection
                  with any contract or commitment whatsoever. This document does
                  not claim to contain all information that an investor or
                  potential investor may require for the purposes of making an
                  investment. Potential investors or existing investors should
                  not construe any information contained herein as advice
                  relating to business, financial, legal, taxation or investment
                  matters. An investment is speculative, and no assurance is or
                  can be given that the investors will receive a return of their
                  capital.
                </p>

                <p>
                  Certain statements made in this report may not be based on
                  historical information or facts and may be "Forward Looking
                  Statements", including those relating to general business
                  plans and strategy, future financial condition and growth
                  prospects, and future developments in industries and
                  competitive and regulatory environments. Actual results may
                  differ materially from any Forward-Looking Statements due to a
                  number of factors, including future and/or unforeseen changes
                  or developments in the business, its competitive environment
                  and political, economic, legal and social conditions in India
                  or other jurisdictions. Given these uncertainties, investors
                  or potential investors are cautioned not to rely on such
                  Forward -Looking Statements, and should rely entirely on their
                  own independent enquiries, investigations and advice regarding
                  any information contained in this presentation. Any decision
                  to invest should only be made after careful review of the
                  product offering documents and after conducting such
                  investigations as the investor deems necessary and following
                  consultation with the investor's own legal, accounting, tax
                  and other advisors in order to make an independent
                  determination of the suitability and consequences of an
                  investment. Any reliance placed by investors or a potential
                  investor on the information contained in this document is
                  wholly at their risk AWMPL, its affiliates, employees,
                  disclaim any and all liability for any direct, indirect or
                  consequential loss or damages suffered by any person as a
                  result of relying on any statement, valuation or report in, or
                  omission from, this document.
                </p>

                <p>
                  Any valuation or ancillary report prepared by a third-party
                  agency (including an independent valuer, credit agency and/or
                  stock exchanges, as the case may be) shall be subject to any
                  updation, changes and/or disclaimers made by such third-party
                  agency and X-B4 shall not be liable for updating any such
                  report.
                </p>
                <p>
                  You acknowledge that you have read and understood the
                  Additional Risk Disclosure & Disclaimers and other legal
                  information mentioned in the website and / or Application and
                  agree to abide by it.
                </p>

                <h3>DISCLAIMER OF WARRANTY AND LIMITATION OF LIABILITY</h3>
                <p>
                  The information on this site is provided "AS IS". X-B4 does
                  not, either expressly or impliedly, warrant the accuracy of
                  the information and materials provided herein for any
                  particular purpose and expressly disclaim any warranties of
                  merchantability or fitness for a particular purpose. X-B4 will
                  not be responsible or liable for any loss, injury, liability
                  or damage that could result from or arise from your usage of
                  the Website and / or Application, including, but not limited
                  to any errors in or omissions from the Website or Application
                  or its content therein and any third party website or content
                  therein directly or indirectly accessed through links made
                  available on the Website or Application, if any, including but
                  not limited to any errors in or omissions there from.
                </p>

                <p>
                  X-B4 does not guarantee the accuracy, validity, reliability,
                  timeliness or completeness of any information or material made
                  available to you for any particular purpose. Neither X-B4, nor
                  any of its, directors, officers or employees, nor any third
                  party vendor will be liable or have any responsibility of any
                  kind for any loss or damage that you incur in the event of any
                  failure or interruption of the Website and/or Application, or
                  resulting from the act or omission of any other party involved
                  in making the Website and / or Application or the data
                  contained therein available to you, or from any other cause
                  relating to your access to, inability to access, or use of the
                  Website and / or Application or these materials, whether or
                  not the circumstances giving rise to such cause may have been
                  within the control of X-B4 or of any vendor providing software
                  or services support. In no event will X-B4 or any such parties
                  be liable to you for any direct, special, indirect,
                  consequential, incidental damages or any other damages of any
                  kind even if X-B4 or any other party have been advised of the
                  possibility thereof.
                </p>

                <h3>WEBSITE AND/OR APPLICATION CONTENT</h3>
                <p>
                  Any and all material and information available through the
                  Website or Application is solely for non-commercial and for
                  your own personal use and for information purposes only.
                </p>

                <p>
                  X-B4 reserves the right, at any time and from time to time, in
                  its own discretion to add, modify, or remove any of the
                  material or information available on the Website and/or
                  Application. These Terms of Use are not intended to, and will
                  not, transfer or grant any rights in or to the information
                  other than those which are specifically described herein, and
                  all rights not expressly granted herein are reserved by X-B4
                  or the third-party providers from whom Avendus has obtained
                  the information. Avendus does not warrant the completeness,
                  timeliness or accuracy or any material and information
                  available on the Website and/or Application.
                </p>

                <p>
                  The materials and information on the Website and/or
                  Application is not intended as an offer or solicitation for
                  the purchase of any stock, any other security, or any
                  financial instrument or to provide any investment service or
                  investment advice in any jurisdiction. Investment services are
                  subject to market risks. User accesses, uses, and relies upon
                  such information at his own risk.
                </p>

                <p>
                  X-B4 may offer an online secured access, whether free or
                  subscription based, to its customers/users as part of
                  additional Website / Application services. X-B4 may in its
                  sole discretion terminate access to or use of the Website
                  and/or Application for any reason, including without
                  limitation where it believes that such customer/user has not
                  acted in accordance with the Terms of Use of the Website and
                  Application.
                </p>

                <h3>User Restrictions</h3>
                <p>
                  You shall not: publish, transmit, or otherwise reproduce any
                  information available on the Website and / or Application, in
                  whole or in part, in any format to any third party without the
                  express written consent of Avendus; alter, obscure, or remove
                  any copyright, trademark or any other notices that are
                  provided to you in connection with the information;
                </p>
                <p>
                  access any part of the Website and Application in an
                  unauthorized manner;
                </p>
                <p>misuse your password in any way;</p>
                <p>
                  engage in any activities related to the Website and
                  Application that are contrary to applicable law, regulation or
                  the terms of any agreements you have with Avendus;
                </p>

                <p>
                  use or cause to be used any robot, spider or any other
                  automatic software or device or process to monitor or copy our
                  Website and Application or its pages, data or content
                  contained therein without our prior expressed written
                  permission; and
                </p>

                <p>
                  alter, decompile or reverse engineer any software component of
                  the Website and / or Application.
                </p>

                <h3>Intellectual Property</h3>
                <p>
                  Unless otherwise stated, copyright and all intellectual
                  property rights in and to all content and material presented
                  on the Website and Application (including but not limited to
                  text, audio, video or graphical images), trademarks and logos
                  appearing on the Website and Application are the property of
                  X-B4 and/or its affiliates and are protected under applicable
                  Indian Laws. No part of any information or material or
                  software from the Website and Application may be (i) copied,
                  removed, photocopied, or duplicated in any form by any means,
                  or (ii) re-distributed without Avendus's prior written
                  consent. If you download any information or material or
                  software from the Website and / or Application, you agree that
                  you will not copy it or remove or obscure any copyright or
                  other notices or legends contained in any such information.
                  Any infringement shall be strongly defended and pursued to the
                  fullest extent permitted by law.
                </p>

                <h3>Links to third party websites</h3>
                <p>
                  This Website and / or Application may provide links to certain
                  third party websites. X-B4 is providing such links solely as a
                  convenience to you. Such third party websites that have links
                  on our Website and/or Application may collect personally
                  identifiable information about you. You are advised to check
                  the data protection policies of these third party websites
                  yourself before using those links/sites.
                </p>

                <h3>Privacy</h3>
                <p>
                  Use of this Website and/or Application may be monitored,
                  tracked and recorded. You acknowledge that we may use,
                  disclose or transfer any information that you provide through
                  this Website and / or Application. For more details please
                  refer to our privacy Policy located at our website.
                </p>

                <h3>Change in Terms & Conditions</h3>
                <p>
                  X-B4 reserves the right, in its sole discretion, without any
                  obligation and without any notice, to change these Terms of
                  Use at any time or to suspend and/or deny access to this
                  Website and/or Application for scheduled or unscheduled
                  maintenance, upgrades, improvements or corrections. X-B4 does
                  not undertake any obligation or responsibility to update or
                  amend any information. X-B4 may discontinue or change any
                  product or service described in or offered on the Website
                  and/or Application at any time in its discretion. Such changes
                  will become effective and binding after their posting on this
                  section of the Website. You are responsible for regularly
                  reviewing and your continued use of the Website and / or
                  Application constitutes your agreement to all such changes. By
                  continuing to use our products and services and the Website
                  and / or Application after any posted revision, the
                  visitor/customer accepts to abide by it.
                </p>

                <h3>Contact Us</h3>
                <p>
                  If you have any questions, comments, or concerns about the
                  Terms of Use, please write to us at info@x-b4.com
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TermsConditions;

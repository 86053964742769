import React from "react";
import { Link } from "react-router-dom";

const PageHeading = ({heading, subheading}) => {
  return (
    <>
      <div className="col-12">
        <div className="page-heading">
          <h2>{heading}</h2>
        </div>
        <div className="page-navigation">
          <ul>
            <li>
              <Link to="/"> Home</Link>
            </li>
            <li>
              <Link to="#" className="text-bold currentlink">
                {subheading}
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default PageHeading;

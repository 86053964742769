import React from "react";
import "./assets/styling/style.scss";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Navigation from "./components/Header/Navigation";
import MobileNavbar from "./components/Header/MobileNavbar";

import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Teams from "./pages/Teams";
import Clients from "./pages/Clients";
import Services from './pages/Services';
import ServiceDetail from "./pages/ServiceDetail";
import Career from "./pages/Career";
import PrivacyPolicy from "./pages/Privacy-Policy";
import XClub from "./pages/XClub";
import TermsConditions from "./pages/Terms-Conditions";
import Resources from "./pages/Resources";
import ResourceData from "./pages/ResourceData";
import Investors from "./pages/Investors";
import InvestorData from "./pages/InvestorData";
import InvestorFiles from "./pages/InvestorFiles";
import Footer from "./components/Footer/Footer";
import ScrollToTop from "./components/ScrollToTop";

const App = () => {
  return (
    <BrowserRouter>
      <Navigation />
      <MobileNavbar />
      <ScrollToTop />
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/about" exact element={<About />} />
        <Route path="/teams" exact element={<Teams />} />
        <Route path="/clients" exact element={<Clients />} />
        <Route path="/about" exact element={<About />} />
        <Route path="/services" exact element={<Services />} />
        <Route path="/services/:url" exact element={<ServiceDetail />} />
        <Route path="/contact" exact element={<Contact />} />
        <Route path="/career" exact element={<Career />} />
        <Route path="/privacy-policy" exact element={<PrivacyPolicy />} />
        <Route path="/x-club" exact element={<XClub />} />
        <Route path="/investor-relations" exact element={<Investors />} />
        <Route path="/investor-relations/:id" exact element={<InvestorData />} />
        <Route path="/investor-relations/:id/investor-files/:url" exact element={<InvestorFiles />} />
        <Route path="/resources" exact element={<Resources />} />
        <Route path="/resources/:id" exact element={<ResourceData />} />
        <Route path="/terms-conditions" exact element={<TermsConditions />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default App;

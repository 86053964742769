import europe from '../../assets/images/contact/europe.jpg';
import india from '../../assets/images/contact/india.jpg';
import middleeast from '../../assets/images/contact/middleeast.jpg';
import northamerica from '../../assets/images/contact/northamerica.jpg';
import singapore from '../../assets/images/contact/singapore.jpg';


export const locations = [
    {
        id: 0,
        img: india,
        name: 'Delhi',
        region: 'asia',
        address: 'Suite 108-109, Hyatt Regency Delhi, Bhikaji Cama Place, Ring Road, New Delhi – 110066',
        mail: 'delhi@x-b4.com',
        phone: '+919971491205',
    },
    {
        id: 1,
        img: india,
        name: 'Mumbai',
        region: 'asia',
        address: '5th floor, Technopolis Knowledge Park, Mahakali Caves Rd, Chakala, Hanuman Nagar, Andheri East, Mumbai, Maharashtra 400093',
        mail: 'mumbai@x-b4.com',
        phone: '+919971491205',
    },
    {
        id: 2,
        img: europe,
        name: 'London',
        region: 'europe',
        address: 'Mayfair Equity Advisory, First Floor, 36 Albemarle Street, Mayfair, London, W1S 4JE, UK',
        mail: 'london@x-b4.com',
        phone: '+919971491205',
    },
    {
        id: 3,
        img: middleeast,
        name: 'Dubai',
        region: 'asia',
        address: 'Level 3, Building C3 , DWTC, Sheikh Zayed Road, P.O.Box 9573 – UAE',
        mail: 'dubai@x-b4.com',
        phone: '+919971491205',
    },
    {
        id: 5,
        img: singapore,
        name: 'Singapore',
        region: 'asia',
        address: 'Capital Square, Level 7, 23 Church Street, Singapore 049481',
        mail: 'singapore@x-b4.com',
        phone: '+919971491205',
    },
    // {
    //     id: 1,
    //     img: northamerica,
    //     name: 'New York',
    //     region: 'namerica',
    //     address: 'RZ 628, Pankha Road,(Near Shiv Temple) Sagapur, New Delhi, Delhi 110045 (AM)',
    //     mail: 'sales@bx-4.com.in',
    //     phone: '+91 89205-11882',
    // },
   
   
]

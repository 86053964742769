import React, { useState } from "react";
import { Link } from "react-router-dom";
import Backdrop from "../Backdrop/Backdrop";
import SideDrawer from "./SideDrawer";
import NavLinks from "./NavLinks";
import MainHeader from "./MainHeader";
import logo from '../../assets/images/logo/newwlogo.png';

const Navigation = () => {
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);

  const closeDrawerHandler = () => {
    setDrawerIsOpen(false);
  };

  const drawerHandle = () => {
    console.log('ha click hua')
    setDrawerIsOpen(!drawerIsOpen);
  };

  return (
    <>
      {drawerIsOpen && <Backdrop onClick={closeDrawerHandler} />}

      <SideDrawer show={drawerIsOpen} onClick={closeDrawerHandler}>
        <nav className="main-navigation__drawer-nav">
          <NavLinks />
        </nav>
      </SideDrawer>

      <MainHeader>
        <div className="navigation__box">
          <Link to="/">
            <img src={logo} alt="logo" width="200" className="img-fluid" />
          </Link>
          <button className="main-navigation__menu-btn" onClick={drawerHandle}>
            {drawerIsOpen ? (
              <i className="fa fa-times" aria-hidden="true"></i>
            ) : (
              <i className="fa fa-bars" aria-hidden="true"></i>
            )}
          </button>
        </div>
        <nav className="main-navigation__header-nav">
          <NavLinks />
        </nav>
        {/* </div> */}
      </MainHeader>
    </>
  );
};

export default Navigation;

import React from "react";
import { NavLink } from "react-router-dom";
import { RiArrowDropDownLine } from "react-icons/ri";



const NavLinks = () => {
  return (
    <ul className="nav-links">
      <li>
        <NavLink to="/" exact>
          Home
        </NavLink>
      </li>
      <li>
        <NavLink to="/about" exact>
          About
        </NavLink>
      </li>
      <li>
        <NavLink to="/services" exact>
          Services
        </NavLink>
      </li>
      {/* <li className="submenu-heading">
        <NavLink to="#" exact>
          Clients
        </NavLink>

        <div className="dropdown-panel">
          <ul className="dropdown-panel-list">
            <li className="submenu-title">
              <NavLink
                to="/clients"
                className="submenu-link"
              >Clients
              </NavLink>
            </li>
            <li className="submenu-title">
              <NavLink
                to="/investor-relations"
                className="submenu-link"
              >Investor Relations
              </NavLink>
            </li>
          </ul>
        </div>
      </li> */}
       <li className="submenu-heading">
        <NavLink to="#" exact>
          Team 
          {/* <RiArrowDropDownLine className="nav-icon"/> */}
        </NavLink>

        <div className="dropdown-panel">
          <ul className="dropdown-panel-list">
            <li className="submenu-title">
              <NavLink
                to="/teams"
                className="submenu-link"
              >Leadership
              </NavLink>
            </li>
            <li className="submenu-title">
              <NavLink
                to="/career"
                className="submenu-link"
              >Career
              </NavLink>
            </li>
          </ul>
        </div>
      </li>
      <li className="submenu-heading">
        <NavLink to="#" exact>
          X-Club 
          {/* <RiArrowDropDownLine className="nav-icon"/> */}
        </NavLink>

        <div className="dropdown-panel">
          <ul className="dropdown-panel-list">
            <li className="submenu-title">
              <NavLink
                to="/resources"
                className="submenu-link"
              >Resources
              </NavLink>
            </li>
            <li className="submenu-title">
              <NavLink
                to="/x-club"
                className="submenu-link"
              >Join Us
              </NavLink>
            </li>
          </ul>
        </div>
      </li>
      <li>
        <NavLink to="/contact" exact>
          Contact
        </NavLink>
      </li>
     
    </ul>
  );
};

export default NavLinks;

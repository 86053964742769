import React, { useEffect, useRef, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { FaLinkedin, FaMapMarkerAlt } from "react-icons/fa";
import { FaPhone, FaEnvelope } from "react-icons/fa6";
import logo from "../../assets/images/logo/newwlogo.png";
import Modal from "react-bootstrap/Modal";
import congrats from "../../assets/images/congrats.png";
import { publicRequest } from "../../url";

const Footer = () => {
  const initalValues = {
    email: ""
  };

  const [formValues, setFormValues] = useState(initalValues);
  const [formErrors, setFormErrors] = useState({});
  const [valid, setValid] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(!show);

  const handleChange = (e) => {
    const { name, value } = e.target; // take name and value from input in destructring
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errorCheck = validate(formValues);
    setFormErrors(errorCheck);
    setIsSubmit(true);
    if (Object.keys(errorCheck).length === 0) {
      successCheck();
    }
  };

  const successCheck = async () => {
    try {
      const res = await publicRequest.post("/subsmail/addNew", formValues);
      if (res.status === 200) {
        handleShow();
        resetInput();
      }
    } catch (err) {
      console.log(err, "err");
      alert(err.response.data.err)
    }
  };

  const resetInput = () => {
    setFormValues(initalValues);
    setValid(false);
    // window.location.reload(false);
  };

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    //email
    if (!values.email) {
      errors.email = "Email is Required";
    }
    if (!regex.test(values.email) && values.email) {
      errors.email = "Email is not valid";
    }

    return errors;
  };

  return (
    <>
      <section className="footer">
        <div className="subscription-box">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-12">
                <div className="subs-text">
                  <p>Stay updated with the latest at X-B4</p>
                </div>
              </div>
              <div className="col-lg-4 col-12">
                <div className="subs-heading">
                  <h2>Subscribe</h2>
                  <p>to our newsletter</p>
                </div>
              </div>
              <div className="col-lg-4 col-12">
                <div className="subs-form">
                  <form>
                    <div className="form-row row">
                      <div className="form-group">
                        <div className="form-div">
                          <div className="form-box">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Your Email"
                              name="email"
                              onChange={handleChange}
                              value={formValues.email}
                            />
                            <small className="text-red">
                              {formErrors.email}
                            </small>
                          </div>

                          <button
                            className="subs-submit-btn"
                            type="submit"
                            onClick={handleSubmit}
                          >
                            Subscribe
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <Modal
            show={show}
            onHide={handleShow}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Body>
              <div className="mailing-popup">
                <button className="closed" onClick={handleShow}>
                  +
                </button>
                <img src={congrats} className="img-fluid" />
                <h3>Congrats!!</h3>
                <p>Thank you for joining X-Club. We will be in touch with you shortly.</p>
              </div>
            </Modal.Body>
          </Modal>
        </div>
        <footer className="footer-top">
          <div className="container">
            <div className="row">
              <div className="footer-col col-lg-4 col-md-6 col-sm-6 col-12 sm-mb-3">
                <h3 className="main-heading">
                  <Link to="/">
                    <img src={logo} alt="logo" width="180" />
                  </Link>
                </h3>
                <p className="mb-2 py-1 footer-para">
                  If you have any questions or need help, feel free to contact
                  with our team.
                </p>
                <div className="py-1 contact__number d-flex align-items-center mb-3">
                  <FaPhone className="footer-call-icon" />
                  <a href="#" className="text-call p-0">
                    +91 99714-91205
                  </a>
                </div>
                <ul>
                  <li className="address-li">
                    <FaMapMarkerAlt className="footer-call-icon" />
                    <p className="mb-2 footer-para">
                      Suite 108-109, Hyatt Regency Delhi, Bhikaji Cama Place, Ring
                      Road, New Delhi – 110066
                    </p>
                  </li>
                </ul>
              </div>
              <div className="footer-col col-lg-2 col-md-6 col-sm-6 col-12">
                <h4>Useful Links</h4>
                <ul>
                  <li>
                    <NavLink to="/about"> About Us</NavLink>
                  </li>
                  <li>
                    <NavLink to="/teams">Teams</NavLink>
                  </li>
                  <li>
                    <NavLink to="/services">Our Services</NavLink>
                  </li>
                  {/* <li>
                    <NavLink to="/clients">Clients</NavLink>
                  </li> */}
                  <li>
                    <NavLink to="/career">Careers</NavLink>
                  </li>
                  <li>
                    <NavLink to="/contact">Contact Us</NavLink>
                  </li>
                </ul>
              </div>
              <div className="footer-col col-lg-2 col-md-6 col-sm-6 col-12">
                <h4>What we Do</h4>
                <ul>
                <li>
                    <a href="/services/corporate-fundraising-advisory">
                    Corporate Fundraising Advisory
                    </a>
                  </li>
                  <li>
                    <a href="/services/transaction-advisory">
                      Transaction Advisory
                    </a>
                  </li>
                  <li>
                    <a href="/services/capital-market-solutions">
                      Capital Market Solutions
                    </a>
                  </li>
                  <li>
                    <a href="/services/transaction-structuring">
                      Transaction Structuring
                    </a>
                  </li>
                  <li>
                    <a href="/services/debt-syndication-and-advisory">
                      Debt Syndication and Advisory
                    </a>
                  </li>
                  {/* <li>
                    <a href="/">Investor Relations</a>
                  </li> */}
                </ul>
              </div>
              <div className="footer-col col-lg-4 col-md-6 col-sm-6 col-12">
                <h4>Connect With Us</h4>
                <div className="contact-box mt-30">
                  <div className="py-1 contact__number d-flex align-items-center mb-3">
                    <FaEnvelope className="footer-call-icon" />
                    <a href="#" className="text-call p-0">
                      info@x-b4.com
                    </a>
                  </div>
                </div>
                <div className="social-links mb-4">
                  <a href="mailto:info@x-b4.com" target="_blank">
                    <FaEnvelope />
                  </a>
                  <a href="tel: +919971491205" target="_blank">
                    <FaPhone />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/x-b4-advisory-llp/about/"
                    target="_blank"
                  >
                    <FaLinkedin />
                  </a>
                  {/* <a href="#">
                    <FaTwitter />
                  </a> */}
                </div>
                <div className="appointment-box">
                  <Link to="/contact" className="appoint-btn">
                    Book your appointment
                  </Link>
                  {/* <GoArrowRight className="footer-arrow"/> */}
                </div>
              </div>
            </div>
          </div>
        </footer>
        <div className="footer-bottom">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-12">
                <p className="text-reserve">© 2024 X-B4. All rights reserved</p>
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                <ul>
                  <li>
                    <Link to="/privacy-policy">
                      <p>Privacy Policy</p>
                    </Link>
                  </li>
                  <li>
                    <Link to="/terms-conditions">
                      <p>Terms of Use</p>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;

import { createSlice } from "@reduxjs/toolkit";

export const fileSlice = createSlice({
  name: "file",
  initialState: {
    files: [],
    isFetching: false,
    error: false
  },
  reducers: {
    // get All
    getFileStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    getFileSuccess: (state, action) => {
      state.isFetching = false;
      state.files = action.payload;
    },
    getFileFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    }
  }
});

export const { getFileStart, getFileSuccess, getFileFailure } =
  fileSlice.actions;

export default fileSlice.reducer;

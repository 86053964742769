import React, { useState, useEffect } from "react";
import { publicRequest } from "../url";
import PageHeading from "../components/PageHeading";

const XClub = () => {
  const initialValues = {
    name: "",
    email: "",
    number: "",
    companyName: "",
    designation: "",
    sector: ""
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errorCheck = validate(formValues);
    setFormErrors(errorCheck);
    setIsSubmit(true);
    if (Object.keys(errorCheck).length === 0) {
      // console.log(formValues, 'form values');
      successCheck();
    }
  };

  const successCheck = async () => {
    try {
      const res = await publicRequest.post("/xclub/newquery", formValues);
      if (res.status === 200) {
        console.log(res, 'res data')
        alert(res.data.message)
        resetInput();
      }
    } catch (err) {
      console.log(err, "err");
      alert(err.response.data.err)
    }
  };


  const resetInput = () => {
    setFormValues(initialValues);
  };

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    //name
    if (!/^[a-z A-Z0-9]+$/.test(values.name) || /^[ ]+$/.test(values.name)) {
      errors.name = "Name is not valid";
    }

    if (!values.name) {
      errors.name = "Name is Required";
    }

    if (!values.email) {
      errors.email = "Email is Required";
    }
    if (!regex.test(values.email) && values.email) {
      errors.email = "Email is not valid";
    }

    if (values.number) {
      if (values.number.length < 10) {
        errors.number = "Enter atleast 10 digits";
      }
    }

    if (!values.number) {
      errors.number = "Phone Number is Required";
    }

    if (!values.designation) {
      errors.designation = "Designation is Required";
    }

    if (!values.companyName) {
      errors.companyName = "Company Name is Required";
    }

    if (!values.sector) {
      errors.sector = "Company Sector is Required";
    }

    return errors;
  };

  return (
    <>
      <section className="xclub-page-section page-header">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <PageHeading heading={"Join X-Club"} subheading={"Join X-Club"} />
            </div>
          </div>
        </div>
      </section>

      <section className="xclub-banner">
        <div className="container">
          <div className="row mb-3">
            <div className="col-12">
              <div className="sec-heading">
                <h2
                  style={{
                    fontSize: "1.57rem"
                  }}
                >
                  Stay ahead of the curve with X-Club private events hosted by
                  X-B4
                </h2>
                <p
                  style={{
                    textAlign: "center"
                  }}
                >
                  Join our X-clusive private mailing list and network to gain
                  access to insights and introductions from our esteemed
                  international circle. Be part of a community that stays
                  informed, connected, and ready for what lies ahead in the
                  world of business and finance.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container x-clubcontainer">
          <div className="row align-items-center">
            <div className="col-lg-5 col-12 banner-left">
          
            </div>
            <div className="col-lg-7 col-12 banner-right">
              <div className="enquiry text-left">
                <h3 className="enquiry-heading">
                  Receive X-clusive Insights & Access
                </h3>
                <form>
                  <div className="form-row row">
                    <div className="form-group col-md-12">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Your Name"
                        name="name"
                        onChange={handleChange}
                        value={formValues.name}
                      />
                      <small className="text-red">{formErrors.name}</small>
                    </div>
                    <div className="form-group col-md-6">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Email"
                        name="email"
                        onChange={handleChange}
                        value={formValues.email}
                      />
                      <small className="text-red">{formErrors.email}</small>
                    </div>
                    <div className="form-group col-md-6">
                      <input
                        type="text"
                        className="form-control"
                        name="number"
                        placeholder="Phone Number"
                        value={formValues.number}
                        onChange={handleChange}
                      />
                      <small className="text-red">{formErrors.number}</small>
                    </div>

                    <div className="form-group col-md-6">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Company Name"
                        name="companyName"
                        onChange={handleChange}
                        value={formValues.companyName}
                      />
                      <small className="text-red">
                        {formErrors.companyName}
                      </small>
                    </div>
                    <div className="form-group col-md-6">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Designation"
                        name="designation"
                        onChange={handleChange}
                        value={formValues.designation}
                      />
                      <small className="text-red">
                        {formErrors.designation}
                      </small>
                    </div>
                    <div className="form-group col-md-12">
                      <select
                        name="sector"
                        className="form-control"
                        value={formValues?.sector}
                        onChange={handleChange}
                      >
                        <option value="">Sector of Company</option>
                        <option value="Technology & Business Services">Technology & Business Services</option>
                        <option value="Pharma & Healthcare">Pharma & Healthcare</option>
                        <option value="Industrials">Industrials</option>
                        <option value="Retail & Consumer">Retail & Consumer</option>
                        <option value="Renewable Energy">Renewable Energy</option>
                        <option value="Infrastructure & Real Estate">Infrastructure & Real Estate</option>
                        <option value="Banking & Financial Services">Banking & Financial Services</option>
                        <option value="Energy & Utilities">Energy & Utilities</option>
                        <option value="Hospitality & Leisure">Hospitality & Leisure</option>
                      </select>
                      <small className="text-red">{formErrors.sector}</small>
                    </div>
                    <div className="form-group col-12 text-center">
                      <br />
                      <button type="submit" onClick={handleSubmit}>
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default XClub;

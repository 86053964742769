import React from "react";
import { Link } from "react-router-dom";
import { ImageUrl } from "../../url";

const BlogItem = ({
  service: { resourceName, mainImg, type, _id, content }
}) => {
  return (
    <div className="col-lg-4 col-md-4 col-12 my-3">
      <div className="card bg-white border-1 mb-4 resource-list-card">
        <div className="img-div">
          <img
            src={`${ImageUrl}/resources/` + mainImg[0]?.filename}
            alt=""
            className="img-fluid"
            loading="lazy"
          />
        </div>
        <div className="card-body d-flex px-4 pb-0">
          <div>
            <p className="p-0 m-0 service-type">{type}</p>
            <h4 className="service-name">
              <Link to={`/resources/${_id}`} className="p-0">
                {resourceName}
              </Link>
            </h4>
            <p className="service-description">
            <div dangerouslySetInnerHTML={{ __html: content }}></div>
            </p>
            <Link to={`/resources/${_id}`} className="know-more text-left p-0">
              Know More
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogItem;

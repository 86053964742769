import React from "react";
import { Link } from "react-router-dom";
import { clients } from "../assets/data/clients";
import PageHeading from "../components/PageHeading";
import { FaLinkedin, FaTwitterSquare } from "react-icons/fa";

const Clients = () => {
  return (
    <>
      <section className="client-page page-header team-member">
        <div className="container">
          <div className="row">
            <PageHeading heading={"Our Clients"} subheading={"Clients"} />
          </div>
        </div>
      </section>
      <section className="client-page-data team-member">
        <div className="container">
          <div className="row mb-3">
            <div className="col-12">
              <div className="sec-heading">
                <h2>Best Clients</h2>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            {clients.map((data, index) => {
              return (
                <div className="col-lg-3 col-md-4 col-sm-6 col-12 my-2">
                  <div target="_blank" className="client-box">
                    <div className="img-box">
                      <img src={data.img} alt="team" className="img-fluid" />
                    </div>
                    <div className="content-box mt-2">
                      <h3><a href={data.url} target="_blank">{data.name}</a></h3>
                      <p>{data.about}</p>
                    </div>
                    {/* <div className="industry">
                      <p>{data.industry}</p>
                    </div> */}
                  </div>
                </div>
              );
            })}
          </div>
          {/* <div className="row mt-4">
            <div className="col-12">
                <div className="team-btn">
                    <button><Link to="">Know More</Link></button>
                </div>
            </div>
          </div> */}
        </div>
      </section>{" "}
    </>
  );
};

export default Clients;

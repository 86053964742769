import { userRequest, publicRequest } from "../url";

import {
    getResourceStart,
    getResourceSuccess,
    getResourceFailure,
} from '../redux/resourceRedux';

export const getResources = async (dispatch) => {
    dispatch(getResourceStart());
    try {
      const res = await publicRequest.get("/resources/getAll");
      dispatch(getResourceSuccess(res.data));
    } catch (err) {
      dispatch(getResourceFailure());
    }
  };
export const keyaspects = [
    {
        id: 1,
        ques: 'Effective Communication',
        ans: `Regular and transparent communication with shareholders, investors, regulatory bodies, and other key stakeholders.
        `
    },
    {
        id: 2,
        ques: 'Relationship Building',
        ans: 'Establishing and nurturing relationships based on trust, credibility, and mutual understanding.'
    },
    {
        id: 3,
        ques: 'Alignment of Interests',
        ans: `Ensuring that the interests of all stakeholders are aligned with the objectives of our clients.`
    },
    {
        id: 4,
        ques: 'Conflict Resolution',
        ans: 'Proactively addressing conflicts and resolving issues to maintain positive relationships and minimize disruptions.'
    },
    {
        id: 5,
        ques: 'Risk Management',
        ans: 'Identifying and mitigating risks associated with stakeholder relations to safeguard the interests of our clients.'
    }
]

export const benefits = [
    {
        id: 1,
        ques: 'Enhanced Trust and Credibility',
        ans: ` Building trust and credibility with stakeholders through transparent and proactive communication.`
    },
    {
        id: 2,
        ques: 'Improved Decision-Making',
        ans: 'Access to valuable insights and perspectives from stakeholders to inform strategic decision-making processes.'
    },
    {
        id: 3,
        ques: 'Minimized Disruptions',
        ans: `Proactively addressing conflicts and issues to minimize disruptions and ensure smooth project execution.`
    },
    {
        id: 4,
        ques: 'Strengthened Reputation',
        ans: 'Upholding a positive reputation in the industry by demonstrating a commitment to stakeholder satisfaction and ethical business practices.'
    },
]
import { publicRequest } from "../url";

import {
  getFileStart,
  getFileSuccess,
  getFileFailure
} from "../redux/fileRedux";

export const getFiles = async (dispatch, type, investUrl) => {
  dispatch(getFileStart());
  try {
    const res = await publicRequest.get(`/investors/getFilesByIdType?type=${type}&investUrl=${investUrl}`);
    console.log(res.data, 'res datafor get files by query')
    dispatch(getFileSuccess(res.data));
  } catch (err) {
    dispatch(getFileFailure());
  }
};

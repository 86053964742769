import React, { useState } from "react";
import { FiPlus, FiMinus } from "react-icons/fi";
import { FaTimes } from "react-icons/fa";
import Backdrop from "../Backdrop/Backdrop";
import SideDrawer from "./SideDrawer";
import { Link, NavLink } from "react-router-dom";
import logo from "../../assets/images/logo/newwlogo.png";
import { CgMenuRightAlt } from "react-icons/cg";
import { navbarList } from "../../assets/data/navbardata";

const MobileNavbar = () => {
  const [mobNavbar, setMobNavbar] = useState();

  const changeBackground = () => {
    if (window.scrollY >= 80) {
      setMobNavbar(true);
    } else {
      setMobNavbar(false);
    }
  };

  window.addEventListener("scroll", changeBackground);

  const [drawerIsOpen, setDrawerIsOpen] = useState(false);

  const OpenDrawerHandler = () => {
    setDrawerIsOpen(!drawerIsOpen);
    // setDrawerIsOpen(true);
  };

  const closeDrawerHandler = () => {
    setDrawerIsOpen(false);
  };

  const [clicked, setClicked] = useState(false);

  const toggle = (index) => {
    if (clicked === index) {
      //if clicked question is already active, then close it
      return setClicked(null);
    }

    setClicked(index);
  };

  return (
    <>
      {drawerIsOpen && <Backdrop onClick={closeDrawerHandler} />}

      <SideDrawer show={drawerIsOpen}>
        <div className="mobile-navbar">
          <nav className="mobile-navigation-menu">
            <div className="menu-top title">
              <h2 className="menu-title">Menu</h2>

              <button className="menu-close-btn" onClick={closeDrawerHandler}>
                <FaTimes />
              </button>
            </div>

            <ul className="mobile-menu-category-list">
              <li className="menu-category">
                <Link
                  to="/"
                  className="menu-title"
                  onClick={closeDrawerHandler}
                >
                  Home
                </Link>
              </li>
              <li className="menu-category">
                <Link
                  to="/about"
                  className="menu-title"
                  onClick={closeDrawerHandler}
                >
                  About
                </Link>
              </li>
              <li className="menu-category">
                <Link
                  to="/services"
                  className="menu-title"
                  onClick={closeDrawerHandler}
                >
                  Services
                </Link>
              </li>

              {/* <li className="menu-category">
                <Link
                  to="/clients"
                  className="menu-title"
                  onClick={closeDrawerHandler}
                >
                  Clients
                </Link>
              </li> */}

              {/* <li className="menu-category" onClick={() => toggle(true)}>
                <button className="accordion-menu">
                  <p className="menu-title">Team</p>

                  <div>
                    {clicked === true ? (
                      <FiMinus className="cat-icon" />
                    ) : (
                      <FiPlus className="cat-icon" />
                    )}
                  </div>
                </button>
                {clicked === true ? (
                  <ul className="submenu-category-list">
                    <li className="submenu-category">
                      <NavLink
                        to="/teams"
                        className="submenu-title"
                        onClick={closeDrawerHandler}
                      >
                        Leadership
                      </NavLink>
                    </li>
                    <li className="submenu-category">
                      <NavLink
                        href="/career"
                        className="submenu-title"
                        onClick={closeDrawerHandler}
                      >
                        Career
                      </NavLink>
                    </li>
                  </ul>
                ) : null}
              </li>
              <li className="menu-category" onClick={() => toggle(true)}>
                <button className="accordion-menu">
                  <p className="menu-title">X-Club</p>

                  <div>
                    {clicked === true ? (
                      <FiMinus className="cat-icon" />
                    ) : (
                      <FiPlus className="cat-icon" />
                    )}
                  </div>
                </button>
                {clicked === true ? (
                  <ul className="submenu-category-list">
                    <li className="submenu-category">
                      <NavLink
                        to="/teams"
                        className="submenu-title"
                        onClick={closeDrawerHandler}
                      >
                        Resources
                      </NavLink>
                    </li>
                    <li className="submenu-category">
                      <NavLink
                        href="/career"
                        className="submenu-title"
                        onClick={closeDrawerHandler}
                      >
                        Join Us
                      </NavLink>
                    </li>
                  </ul>
                ) : null}
              </li> */}

               {navbarList.map((item, index) => {
                return (
                  <>
                    <li
                      className="menu-category"
                      onClick={() => toggle(index)}
                      key={index}
                    >
                      <button className="accordion-menu">
                        <p className="menu-title">{item.catname}</p>

                        <div>
                          {clicked === index ? (
                            <FiMinus className="cat-icon" />
                          ) : (
                            <FiPlus className="cat-icon" />
                          )}
                        </div>
                      </button>

                      {clicked === index ? (
                        <ul className="submenu-category-list">
                          {item.subcategory.map((subcat, index) => {
                            return (
                              <>
                                <li className="submenu-category">
                                  <Link
                                    to={`${subcat.url}`}
                                    className="submenu-title"
                                    onClick={closeDrawerHandler}
                                    key={index}
                                  >
                                    {subcat.subcatname}
                                  </Link>
                                </li>
                              </>
                            );
                          })}
                        </ul>
                      ) : null}
                    </li>
                  </>
                );
              })} 

              {/* <li className="menu-category">
                <Link
                  to="/teams"
                  className="menu-title"
                  onClick={closeDrawerHandler}
                >
                  Team
                </Link>
              </li>

              <li className="menu-category">
                <Link
                  to="/"
                  className="menu-title"
                  onClick={closeDrawerHandler}
                >
                  X-Club
                </Link>
              </li> */}
              <li className="menu-category">
                <Link
                  to="/contact"
                  className="menu-title"
                  onClick={closeDrawerHandler}
                >
                  Contact
                </Link>
              </li>
            </ul>

            <div>
              <ul className="mobile-social-container">
                <li>
                  <a href="#" className="social-link">
                    {/* <BsFacebook /> */}
                  </a>
                </li>

                <li>
                  <a href="#" className="social-link">
                    {/* <BsTwitter /> */}
                  </a>
                </li>

                <li>
                  <a href="#" className="social-link">
                    {/* <BsInstagram /> */}
                  </a>
                </li>

                <li>
                  <a href="#" className="social-link">
                    {/* <BsLinkedin /> */}
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </SideDrawer>

      <div
        className={
          mobNavbar ? "mobile-navbar-heading active" : "mobile-navbar-heading"
        }
      >
        <div className="main-navigation__title">
          <Link to="/">
            <img src={logo} alt="logo" width="150" />
          </Link>
        </div>

        <div className="mobile-bottom-navigation">
          <button className="action-btn" onClick={OpenDrawerHandler}>
            <CgMenuRightAlt className="mobile-menu-icon" />
          </button>
        </div>
      </div>
    </>
  );
};

export default MobileNavbar;

import React from "react";
import CountUp from "react-countup";

const Numbers = () => {
  return (
    <section className="blog_wrapper">
      <div className="newsletter_wrapper">
        <div className="container py-5">
          <div className="row">
            <div className="col-lg-3 col-md-4 col-sm-6 col-12 my-3">
              <div className="couter-content">
                <h3 className="counter-number">
                  <CountUp end={15} duration={2.75} />
                </h3>
                <p>Professionals Across Advisory</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-12 my-3">
              <div className="couter-content">
                <h3 className="text-white counter-number">
                  <CountUp end={100} duration={2.75} />
                  <span> +</span>
                </h3>
                <p>Years of Experience</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-12 my-3">
              <div className="couter-content">
                <h3 className="text-white counter-number">
                  <span>$</span>
                  <CountUp end={5} duration={2} />
                  <span> Bn</span>
                </h3>
                <p>Cumulative value of Transaction</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-12 my-3">
              <div className="couter-content">
                <h3 className="text-white counter-number">
                  <CountUp end={5} duration={2} />
                </h3>
                <p>Offices in World</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Numbers;

import React from "react";
import PageHeading from "../components/PageHeading";
import { services } from "../assets/data/services";
import { Link } from "react-router-dom";

import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import { keyaspects, benefits } from "../assets/data/stakes";
import Sectors from "../components/Sectors";

import { TbCirclesRelation } from "react-icons/tb";
import { FaGlobeAsia, FaAward } from "react-icons/fa";
import { MdDashboard } from "react-icons/md";

const Services = () => {
  return (
    <>
      <section className="service-list-page-section page-header">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <PageHeading heading={"Our Services"} subheading={"Services"} />
            </div>
          </div>
        </div>
      </section>

      <section className="service-list team-member">
        <div className="container">
          <div className="row mb-5">
            <div className="col-12">
              <div className="sec-heading">
                <h2>Provide Quality Services</h2>
                <p>
                  We provide a range of comprehensive financial services,
                  including:
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            {services.map((data, index) => {
              return (
                <div className="col-lg-4 col-md-6 col-sm-6 col-12 my-2">
                  <div className="service-box">
                    <div className="icon-box">{data.img}</div>
                    <div className="content-box">
                      <h3>{data.name}</h3>
                      <p>{data.text}</p>
                    </div>
                    <div className="link-box">
                      <Link to={`/services/${data.url}`}>Explore More</Link>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <section className="stakeholder-section py-5 mt-4 mb-4">
        <div className="container">
          <div className="row mb-5">
            <div className="col-12">
              <div className="sec-heading">
                <h2 style={{ color: "#000" }}>Managing Stakeholders</h2>
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-center">
            <div className="col-lg-6 col-md-6 col-12">
              <div className="dashboard-wrapper">
                <div>
                  <p className="stake-para">
                    At X-B4, we prioritise effective communication and
                    relationship management with various stakeholders to ensure
                    successful outcomes for our clients. Our approach to
                    stakeholder management is guided by transparency, trust, and
                    alignment of interests throughout the financial process.
                  </p>
                </div>
                <Tab.Container defaultActiveKey="parent">
                  <Nav variant="tabs" className="dashboard-topbar">
                    <Nav.Item>
                      <Nav.Link eventKey="parent">Key Aspects</Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                      <Nav.Link eventKey="kid">Benefits</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content className="dashboard-content">
                    <Tab.Pane eventKey="parent">
                      <div className="row">
                        <div className="col-lg-12 col-12 faq-flex">
                          {keyaspects?.map((faq, id) => {
                            return (
                              <div className="parent-item">
                                <h4
                                  style={{ width: "100%" }}
                                  className="accordion-caption"
                                >
                                  {faq.ques}
                                </h4>
                                <p>{faq.ans}</p>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                  <Tab.Content className="dashboard-content">
                    <Tab.Pane eventKey="kid">
                      <div className="row">
                        <div className="col-lg-12 col-12 faq-flex">
                          {benefits?.map((faq, id) => {
                            return (
                              <div className="parent-item">
                                <h4
                                  style={{ width: "90%" }}
                                  className="accordion-caption"
                                >
                                  {faq.ques}
                                </h4>{" "}
                                <p>{faq.ans}</p>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-12 d-flex align-items-center">
              <img
                src="https://wp1.themevibrant.com/newwp/financer/wp-content/uploads/2024/05/Image-1.png"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </section>

   

      <Sectors total={20}/>

      <section className="advantages">
        <div className="container">
          <div className="row mb-5">
            <div className="col-12">
              <div className="sec-heading">
                <h2>Why Choose X-B4 ?</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-4 colsm-6 col-12 my-3">
              <div className="adv-box">
                <TbCirclesRelation className="adv-icon" />
                <h3>Client-Centric Approach</h3>
                <p>
                  We prioritize client relationships and strive to deliver
                  personalized solutions that align with your goals and
                  objectives.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 colsm-6 col-12 my-3">
              <div className="adv-box">
                <FaAward className="adv-icon" />
                <h3>Expertise and Experience</h3>
                <p>
                  Our team comprises seasoned professionals with extensive
                  experience in investment banking, finance, and corporate
                  strategy, enabling us to provide best-in-class advisory
                  services.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 colsm-6 col-12 my-3">
              <div className="adv-box">
                <MdDashboard className="adv-icon" />
                <h3>Holistic Approach</h3>
                <p>
                As your dedicated house banker, X-B4 becomes an extension of your corporate team, ensuring advisory services are seamlessly aligned with flawless execution.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 colsm-6 col-12 my-3">
              <div className="adv-box">
                <FaGlobeAsia className="adv-icon" />
                <h3>Global Reach</h3>
                <p>
                  With a global network of partners and affiliates, we have the
                  capability to execute transactions across geographies and
                  industries, providing our clients with access to diverse
                  opportunities and markets.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Services;

import React from "react";
import { Link } from "react-router-dom";

const XComp = () => {
  return (
    <>
      <section className="xclub-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="club-content">
                <h3>
                Stay ahead of the curve with X-Club
                </h3>
                <p>Join our X-clusive private club to gain access to insights and introductions from our esteemed international circle.</p>
              </div>
            </div>
            <div className="col-12">
              <div className="club-btn mt-3">
                <Link to="/x-club">Join Us</Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default XComp;

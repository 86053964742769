import { RiSecurePaymentLine, RiFundsFill } from "react-icons/ri";
import { GoGraph } from "react-icons/go";
import { MdCorporateFare } from "react-icons/md";
import { TbCirclesRelation } from "react-icons/tb";
import { FaUsersViewfinder } from "react-icons/fa6";

import corpFund from '../images/services/corp-fund.png';
import transaction from '../images/services/transaction-adv.png';
import capt from '../images/services/capt-mark.png';
import debt from '../images/services/debt.png';
import trans from '../images/services/trans.png';

export const services = [
  {
    id: 0,
    img: <RiFundsFill className="icons service-icon" />,
    featuredImg: corpFund,
    url: "corporate-fundraising-advisory",
    name: "Corporate Fundraising Advisory",
    text: `Expert guidance and innovative strategies for securing the necessary capital to drive your company's growth and success. We specialize in tailored debt and equity financing solutions for large enterprises.`,
    desc: "We provide end-to-end M&A and private fund-raise support, from strategy development to deal closure, with expertise in due diligence and transaction documentation. Leveraging our extensive network with commercial banks and asset managers, we facilitate successful debt securing. Navigate corporate finance confidently with our transaction advisory, covering M&A, divestitures, joint ventures, and debt restructuring. Our tailored solutions ensure value optimization and strategic goal achievement, with seamless execution at every transaction stage.",
    features: [
      {
        heading: "Mergers & Acquisitions (M&A) Strategies",
        desc: "Strategic guidance for mergers, acquisitions, and joint ventures to maximise value and minimise risk."
      },
      {
        heading: "Divestitures & Joint Ventures",
        desc: "Assistance with divestitures and joint venture structuring to optimise portfolio management."
      },
      {
        heading: "Debt Restructuring",
        desc: "Support for debt restructuring initiatives to improve financial flexibility and stability."
      },
      {
        heading: "Strategic Transaction Planning",
        desc: "Customised planning to align transactions with long-term business objectives."
      },
      {
        heading: "Due Diligence Support",
        desc: "Thorough due diligence services to mitigate risks and uncover potential opportunities."
      },
    ],
    advantages: [
      "Expert Guidance from Seasoned Professionals",
      "Tailored Solutions to Meet Your Unique Needs",
      "Seamless Execution and Timely Delivery",
      "Maximisation of Value and Risk Mitigation",
      "Strategic Insights to Support Long-Term Growth"
    ]
  },
  {
    id: 1,
    img: <RiSecurePaymentLine className="icons service-icon" />,
    featuredImg: transaction,
    url: "transaction-advisory",
    name: "Transaction Advisory",
    text: `Navigate the complexities of corporate finance with our
        expert guidance. From M&A strategies to equity restructuring,
        we offer tailored solutions to meet your unique needs.`,
    desc: "We provide end-to-end M&A and private fund-raise support, from strategy development to deal closure, with expertise in due diligence and transaction documentation. Leveraging our extensive network with commercial banks and asset managers, we facilitate successful debt securing. Navigate corporate finance confidently with our transaction advisory, covering M&A, divestitures, joint ventures, and debt restructuring. Our tailored solutions ensure value optimization and strategic goal achievement, with seamless execution at every transaction stage.",
    features: [
      {
        heading: "Mergers & Acquisitions (M&A) Strategies",
        desc: "Strategic guidance for mergers, acquisitions, and joint ventures to maximise value and minimise risk."
      },
      {
        heading: "Divestitures & Joint Ventures",
        desc: "Assistance with divestitures and joint venture structuring to optimise portfolio management."
      },
      {
        heading: "Debt Restructuring",
        desc: "Support for debt restructuring initiatives to improve financial flexibility and stability."
      },
      {
        heading: "Strategic Transaction Planning",
        desc: "Customised planning to align transactions with long-term business objectives."
      },
      {
        heading: "Due Diligence Support",
        desc: "Thorough due diligence services to mitigate risks and uncover potential opportunities."
      },
    ],
    advantages: [
      "Expert Guidance from Seasoned Professionals",
      "Tailored Solutions to Meet Your Unique Needs",
      "Seamless Execution and Timely Delivery",
      "Maximisation of Value and Risk Mitigation",
      "Strategic Insights to Support Long-Term Growth"
    ]
  },
  {
    id: 2,
    img: <GoGraph className="icons service-icon" />,
    featuredImg: capt,
    url: "capital-market-solutions",
    name: "Capital Market Solution",
    text: `Enhance shareholder value and expand your reach with our
        capital market expertise. Whether it's an IPO, private
        placement, or regulatory compliance, we help you achieve
        your goals.`,
    desc: "X-B4 enhances shareholder value and market reach through expert capital market guidance. Our seasoned professionals offer strategic advice on IPOs, private placements, and regulatory compliance, ensuring successful outcomes. We facilitate positioning and outreach to public market investors, managing relations with mutual funds, FIIs, and others to maintain visibility and communication. With a rich track record in public market solutions, including IPOs, private placements, and secondary offerings, we provide comprehensive advisory on regulatory requirements and investment readiness. Trust X-B4 for tailored strategies and industry insights to unlock shareholder value and achieve financial goals in the dynamic capital markets landscape.",
    features: [
      {
        heading: "Initial Public Offerings (IPO)",
        desc: "Assistance with IPO processes to access public capital markets."
      }, {
        heading: "Private Placements",
        desc: "Customised private placement solutions tailored to your specific financing needs."
      },
      {
        heading: "Regulatory Compliance",
        desc: "Guidance on regulatory requirements and compliance issues for public market transactions."
      },
      {
        heading: "Market Analysis & Strategy",
        desc: "Comprehensive market analysis to develop effective capital market strategies."
      },
      {
        heading: "Investor Relations Support",
        desc: "Support to help client companies attract and retain investors, ensuring transparency, compliance, and strategic communication."
      }
    ],
    advantages: [
      "Access to Public Markets with Confidence",
      "trategic Guidance from Industry Experts",
      "Customized Solutions to Fit Your Needs",
      "Regulatory Compliance and Risk Management",
      "nhanced Shareholder Value and Market Visibility"
    ]
  },
  {
    id: 3,
    img: <MdCorporateFare className="icons service-icon" />,
    featuredImg: trans,
    name: "Transaction Structuring",
    url: "transaction-structuring",
    text: `Transaction Structuring Optimize tax efficiency and regulatory compliance with our sophisticated structuring solutions. Our team combines in-depth knowledge with innovative strategies to maximize deal value.`,
    desc: `X-B4 optimizes tax efficiency, regulatory compliance, and deal structuring with sophisticated solutions. Our expert team leverages in-depth knowledge to craft tailored transaction structures, maximizing deal value while minimizing risks. Whether it's cross-border transactions, tax-efficient structures, or regulatory considerations, we provide strategic insights to support your objectives. With cutting-edge expertise in tax laws and regulations, including compliance with SEBI, FEMA, and RBI regulations, we offer customized solutions to balance commercial and regulatory needs. From tax structuring for acquisitions to bespoke solutions for family offices, including succession planning and trust setup, trust X-B4 for comprehensive and strategic transaction support.`,
    features: [
      {
        heading: "Tax-Efficient Deal Structuring",
        desc: "Structuring transactions to optimize tax implications and minimize tax liabilities."
      },
      {
        heading: "Regulatory Compliance Solutions",
        desc: "Ensuring compliance with regulatory requirements to mitigate legal risks."
      },
      {
        heading: "Cross-Border Transaction Support",
        desc: "Guidance on cross-border transactions to navigate international regulations and complexities."
      },
      {
        heading: "Due Diligence & Risk Assessment",
        desc: "Thorough due diligence and risk assessment to identify and mitigate potential risks."
      },
      {
        heading: "Legal & Financial Structuring",
        desc: "Legal and financial structuring expertise to optimize deal terms and minimize legal exposure."
      }
    ],
    advantages: [
      "Maximization of Deal Value and Efficiency",
      "Minimization of Risks and Regulatory Exposure",
      "Customized Solutions to Address Unique Needs",
      "Strategic Insights for Long-Term Success",
      "Timely Execution and Seamless Integration"
    ]
  },
  {
    id: 4,
    img: <FaUsersViewfinder className="icons service-icon" />,
    name: "Debt Syndication and Advisory",
    featuredImg: debt,
    url: "debt-syndication-and-advisory",
    text: `Access tailored financing solutions to fuel your growth ambitions. From structured debt to cross-border projects funding, we offer comprehensive advisory services to meet your capital needs.`,
    desc: `X-B4 offers tailored financing solutions to drive your growth ambitions with confidence. From structured debt to cross-border project funding, our comprehensive advisory services cater to your capital needs and expansion goals. Whether you require debt syndication, refinancing, or project finance solutions, our team collaborates closely with you to identify opportunities and execute strategies aligned with your financial objectives. Serving various Corporates, SMEs, and Mid-Corporate clients, we provide structured financing solutions across diverse areas such as Structured Debt, Term Loans, Project Finance, and Acquisition Finance. With expertise in both domestic and international markets, including cross-border experience, trust X-B4 as your single-point advisor and arranger for raising funds through various debt instruments.
    `,
    features: [
      {
        heading: "Debt Syndication",
        desc: "Structuring and syndication debt financing solutions tailored to your specific requirements."
      },
      {
        heading: "Refinancing Solutions",
        desc: "Assistance with refinancing existing debt to improve terms and lower costs."
      },
      {
        heading: "Project Finance Advisory",
        desc: "Advisory services for project finance initiatives to fund capital-intensive projects such as Green field and Brown field expansion opportunities."
      },
      {
        heading: "Cross-Border Funding",
        desc: "Access to cross-border funding opportunities to support international expansion."
      },
      {
        heading: "Structured Debt Solutions",
        desc: "Structuring customised debt solutions to meet your unique financing needs."
      }
    ],
    advantages: [
      "Tailored Financing Solutions to Support Growth",
      "Expert Advisory Services to Navigate Complex Financial Transactions",
      "Access to Cross-Border Funding Opportunities",
      "Strategic Insights to Optimise Financing Structures",
      "Timely Execution and Support Throughout the Process"
    ]
  },
  {
    id: 1,
    img: <TbCirclesRelation className="icons service-icon" />,
    featuredImg: debt,
    name: 'Investor Relations',
    url: "investor-relations",
    text: `X-B4 can help companies achieve their strategic objectives by building and maintaining strong relationships with the investment community, which results in increased investor confidence, better access to capital, and ultimately increased shareholder value.`,
    features: [
      {
        heading: "Communication & Branding",
        desc: `<ul>
        <li>Investor Presentation</li>
        <li>Corporate Fact Sheet</li>
        <li>Q&A Preparation</li>
        <li>Investor/Analyst Feedback</li>
        <li>IR Website Content & Messaging</li>

        </ul>`
      },
      {
        heading: "Institutional Participation",
        desc: `<ul>
        <li>Sector Focused MFs, FIIs</li>
        <li>Price Efficiency</li>
        <li>Long Term Stabilization</li>
        <li>Corporate Governance</li>
        <li>Improved Market Transparency</li>
        </ul>`
      },
      {
        heading: "Corporate Restructuring",
        desc: `<ul>
        <li>Focus on Core Business</li>
        <li>Enhanced Financial Performance</li>
        <li>Increased Shareholder value</li>
        <li>Realizing Synergies</li>
        <li>Adaption to Market Changes</li>
        </ul>`
      },
      {
        heading: "Consistent Reporting",
        desc: `<ul>
        <li>Investor Confidence</li>
        <li>Regulatory Compliance</li>
        <li>Stakeholder communication</li>
        <li>Benchmarking</li>
        <li>Forecasting & Planning</li>
        <li>Internal Performance Valuation</li>
        </ul>`
      },
      {
        heading: "Increasing Broker Coverage",
        desc: `<ul>
        <li>Ranging from Retail to Institutional</li>
        <li>Increased Visibility</li>
        <li>Expanded Investor Base</li>
        <li>Enhanced Credibility</li>
        <li>Improved Stock Liquidity</li>
        <li>Valuation Support</li>
        <li>Competitive Analysis</li>
        <li>Long term Support</li>
        </ul>`
      }
    ],
    advantages: [
      "Protect and Increase shareholder value",
      "Helps client to compete for capital and mindshare of investors",
      "Provide details of client share ownership pattern, comprising both promoters and non-promoters.",
      "Work closely with the management team and IROs to craft a powerful investment narrative and delivered customised, immediately actionable communication recommendations.",
      "Partner in consistently communicating your story to the capital markets."
    ]
  },
  // {
  //   id: 5,
  //   img: <TbCirclesRelation className="icons service-icon" />,
  //   name: "Investor Relations Services",
  //   url: "investor-relation-services",
  //   text: `Support to help client companies attract and retain investors, ensuring transparency, compliance, and strategic communication.
  //   `,
  //   features: [
  //     {
  //       heading: "Debt Syndication",
  //       desc: "Structuring and syndication debt financing solutions tailored to your specific requirements."
  //     },
  //     {
  //       heading: "Refinancing Solutions",
  //       desc: "Assistance with refinancing existing debt to improve terms and lower costs."
  //     },
  //     {
  //       heading: "Project Finance Advisory",
  //       desc: "Advisory services for project finance initiatives to fund capital-intensive projects such as Green field and Brown field expansion opportunities."
  //     },
  //     {
  //       heading: "Cross-Border Funding",
  //       desc: "Access to cross-border funding opportunities to support international expansion."
  //     },
  //     {
  //       heading: "Structured Debt Solutions",
  //       desc: "Structuring customised debt solutions to meet your unique financing needs."
  //     }
  //   ],
  //   advantages: [
  //     "Tailored Financing Solutions to Support Growth",
  //     "Expert Advisory Services to Navigate Complex Financial Transactions",
  //     "Access to Cross-Border Funding Opportunities",
  //     "Strategic Insights to Optimise Financing Structures",
  //     "Timely Execution and Support Throughout the Process"
  //   ]
  // }

];

import React from "react";
import { teams } from "../assets/data/team";
import { FaLinkedin, FaTwitterSquare } from "react-icons/fa";
import { Link } from "react-router-dom";
import PageHeading from "../components/PageHeading";

const Teams = () => {
  return (
    <>
      <section className="team-page-section page-header">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <PageHeading heading={"Our Teams"} subheading={"Teams"}/>
            </div>
          </div>
        </div>
      </section>

      <section className="team-member">
        <div className="container">
          <div className="row mb-3">
            <div className="col-12">
              <div className="sec-heading">
                <h2>operating team</h2>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            {teams.map((data, index) => {
              return (
                <div className="col-lg-3 col-md-4 col-sm-6 col-12 my-2">
                  <div className="team-box">
                    <div className="img-box">
                      <img src={data.img} alt="team" className="img-fluid" />
                    </div>
                    <div className="content-box">
                      <h3>
                        {data.name} <span>({data.post})</span>
                      </h3>
                      <p>{data.text}</p>
                      <p className="team-para"> 
                      {data.about}
                    </p>
                      <div className="social-box">
                        <a href={data?.link} target="_blank">
                          <FaLinkedin className="team-social-icon" />
                        </a>
                        {/* <a href="#" target="_blank">
                          <FaTwitterSquare className="team-social-icon" />
                        </a> */}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          {/* <div className="row mt-4">
            <div className="col-12">
                <div className="team-btn">
                    <button><Link to="">Know More</Link></button>
                </div>
            </div>
          </div> */}
        </div>
      </section>
    </>
  );
};

export default Teams;

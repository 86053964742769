import smc from '../images/clients/smc.png';
import mothersparsh from '../images/clients/mothersparsh.avif';
import tradeimpex from '../images/clients/tradeimpex.png';
import alive from '../images/clients/alive.jpg';
import dsgroup from '../images/clients/dsgroup.png';
import jktyre from '../images/clients/jktyre.jpg';
import lionservices from '../images/clients/lionservices.png';
import maxhealthcare from '../images/clients/maxhealthcare.svg';
import unominda from '../images/clients/unominda.png';
import paytm from '../images/clients/paytm.svg';
import dentsu from '../images/clients/dentsu1.png';
import sandhar from '../images/clients/sandhar.png';

export const clients = [
    {
        id: 1,
        name: "Mother Sparsh",
        img: mothersparsh,
        url: 'https://mothersparsh.com/',
        about: 'Mother Sparsh is one of the most trusted and loved mother and baby products brands in India. Inspired by timeless Indian Values, and Rituals.',
        industry: 'Retails & E-commerce',
    },
    {
        id: 2,
        name: "SMC",
        img: smc,
        url: 'https://www.smctradeonline.com/',
        about: 'SMC is one of the leading and well-diversified financial services companies in India offering services across brokerage.',
        industry: 'Finance'
    },
    {
        id: 3,
        name: "Trade Impex",
        img: tradeimpex,
        url: 'https://www.tradeimpexpolymers.com/',
        about: 'Tradeimpex Polymers India Pvt. Ltd. is a sales & distribution company under the stewardship of a dynamic and ingenious professionals.',
        industry: 'Sales & Distribution'
    },
    {
        id: 1,
        name: "Alive Wellness",
        img: alive,
        url: 'https://alivewellnessclinics.com/',
        about: 'At Alive Wellness Clinics, our legacy is one of excellence, and our commitment extends far beyond aesthetics. We believe in providing holistic solutions.',
        industry: 'Retails & E-commerce',
    },
    {
        id: 2,
        name: "Ds Group",
        img: dsgroup,
        url: 'https://www.dsgroup.com/',
        about: 'The DS Group is a Multi-Business Corporation and one of the leading FMCG conglomerate with strong Indian and International presence.',
        industry: 'Finance'
    },
    {
        id: 2,
        name: "JK Tyre",
        img: jktyre,
        url: 'https://www.jktyre.com/',
        about: 'The flagship company of JK Organisation, JK Tyre & Industries Ltd is one of India’s foremost tyre manufacturers and is also amongst the top 25 manufacturers in the world.',
        industry: 'Finance'
    },
    {
        id: 2,
        name: "Lion Services",
        img: lionservices,
        url: 'https://www.lionservice.com/',
        about: 'Lion Services Ltd. (LSL), the flagship company of Global Excellence Group, is a pioneer in providing environmental hygiene services.',
        industry: 'Finance'
    },
    {
        id: 2,
        name: "Max Healthcare",
        img: maxhealthcare,
        url: 'https://www.maxhealthcare.in/',
        about: 'is one of India’s largest healthcare organizations. We operate 19 healthcare facilities (4000+ beds), 30+ specialities and 5000+ clinicians.',
        industry: 'Finance'
    },
    {
        id: 2,
        name: "Uno Minda",
        img: unominda,
        url: 'https://www.unominda.com/',
        about: 'Uno Minda Limited (formerly Minda Industries Limited) is a leading global manufacturer of proprietary automotive solutions and systems supplying to OEMs as Tier-1.',
        industry: 'Finance'
    },
    {
        id: 2,
        name: "Paytm",
        img: paytm,
        url: 'https://paytm.com/',
        about: 'Pay anyone, everywhere. Make contactless & secure payments in-stores or online.',
        industry: 'Finance'
    },
    {
        id: 2,
        name: "Dentsu",
        img: dentsu,
        url: 'https://www.dentsu.com/',
        about: 'Dentsu is an integrated growth and transformation partner to the world’s leading organizations. Founded in 1901 in Tokyo, Japan, and now present in over 145 countries and regions.',
        industry: 'Finance'
    },
    {
        id: 2,
        name: "Sandhar",
        img: sandhar,
        url: 'https://sandhargroup.com/',
        about: 'At Sandhar, we focus on customer centric components, mainly catering to Original Equipment Manufacturers (OEM). With a unique motto of ‘Growth.',
        industry: 'Finance'
    },
]
import React from "react";
import PageHeading from "../components/PageHeading";
import { useParams } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { ImageUrl } from "../url";
import { Link } from "react-router-dom";
import { investorType } from "../assets/data/investType";

const InvestorData = () => {
  const { id } = useParams();
  console.log(id, "ids");

  const clientData = useSelector((state) =>
    state.client.clients.find((resource) => resource._id === id)
  );

  console.log(clientData, "resource data");
  return (
    <>
      <section className="investor-sec page-header">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <PageHeading
                heading={"Our Clientele"}
                subheading={clientData.clientName}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="team-member investor-type">
        <div className="container">
          <div className="row mb-3">
            <div className="col-12">
              <div className="sec-heading">
                <h2>{clientData?.clientName}</h2>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            {investorType.map((data, index) => {
              return (
                <div className="col-lg-3 col-md-4 col-sm-6 col-12 my-4">
                  <div className="investor-box py-4">
                    <Link to={`/investor-relations/${id}/investor-files/${data.url}`}>
                      <div className="img-box mb-4">
                        <img
                          src={data.img}
                          alt={data.name}
                          className="img-fluid"
                          loading="lazy"
                        />
                      </div>
                      <div className="content-box">
                        <h3>{data.name}</h3>
                      </div>
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default InvestorData;

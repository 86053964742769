import React, { useState } from "react";
import ContactForm from "../components/Contact/ContactForm";
import { MdPhone } from "react-icons/md";
import { FaRegEnvelope } from "react-icons/fa";
import { FiMapPin } from "react-icons/fi";
import { locations } from "../assets/data/location";

const Contact = () => {
  const [currLoc, setCurrLoc] = useState(locations);
  const [currIndex, setCurrIndex] = useState(0);

  console.log(currLoc, "curr eleem");

  const filterItem = (item) => {
    console.log("filter", item);
    setCurrIndex(item);
  };

  return (
    <>
      <section className="contact-section mt-07">
        <div className="page-img">
          <img
            src={currLoc[currIndex]?.img}
            className="page-main-img img-fluid"
            alt="page-img"
          />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              {/* <div className="page-heading">
                <h1>Contact Us</h1>
                <p>Locate our office or get in touch with us</p>
              </div> */}
            </div>
            <div className="col-12 loc-col">
              <div className="region-div">
                <ul className="location-list">
                  <li>
                    <div className="location-filter">
                      <input
                        className="location-input"
                        type="radio"
                        id="delhi"
                        name="category"
                        onChange={() => filterItem(0)}
                      />
                      <label
                        htmlFor="delhi"
                        className={
                          currIndex === 0
                            ? "radio-label checked"
                            : "radio-label"
                        }
                      >
                        Delhi
                      </label>
                    </div>
                  </li>
                  <li>
                    <div className="location-filter">
                      <input
                        className="location-input"
                        type="radio"
                        id="mumbai"
                        name="category"
                        onChange={() => filterItem(1)}
                      />
                      <label
                        htmlFor="mumbai"
                        className={
                          currIndex === 1
                            ? "radio-label checked"
                            : "radio-label"
                        }
                      >
                        Mumbai
                      </label>
                    </div>
                  </li>

                  <li>
                    <div className="location-filter">
                      <input
                        className="location-input"
                        type="radio"
                        id="london"
                        name="category"
                        onChange={() => filterItem(2)}
                      />
                      <label
                        htmlFor="london"
                        className={
                          currIndex === 2
                            ? "radio-label checked"
                            : "radio-label"
                        }
                      >
                        London
                      </label>
                    </div>
                  </li>

                  <li>
                    <div className="location-filter">
                      <input
                        className="location-input"
                        type="radio"
                        id="dubai"
                        name="category"
                        onChange={() => filterItem(3)}
                      />
                      <label
                        htmlFor="dubai"
                        className={
                          currIndex === 3
                            ? "radio-label checked"
                            : "radio-label"
                        }
                      >
                        Dubai
                      </label>
                    </div>
                  </li>

                  <li>
                    <div className="location-filter">
                      <input
                        className="location-input"
                        type="radio"
                        id="singapore"
                        name="category"
                        onChange={() => filterItem(4)}
                      />
                      <label
                        htmlFor="singapore"
                        className={
                          currIndex === 4
                            ? "radio-label checked"
                            : "radio-label"
                        }
                      >
                        Singapore
                      </label>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <Location /> */}
      <section className="contact-info">
        <div className="container pt-5">
          <div className="row contact-content">
            <div className="contact-form-section col-lg-6 col-md-6 col-12 my-2">
              <ContactForm />
            </div>

            <div className="contact-text-section col-lg-6 col-md-6 col-12 my-2">
              {/* <h4 className="contact-heading">Send a Message</h4>
              <h2 className="contact-head">Get in Touch And We’ll Help You</h2> */}
              <ul className="contact-list">
                <li className="contact-lists">
                  {/* {currIndex === 4 ? (
                    ""
                  ) : (
                    <> */}
                      <div className="contact-icon">
                        <FiMapPin />
                      </div>
                      <div className="contact-text">
                        <h4 className="contact-tect-heading">Office Address</h4>
                        <p className="text-left">
                          {currLoc[currIndex]?.address}
                        </p>
                      </div>
                    {/* </>
                  )} */}
                </li>
                <li className="contact-lists">
                  <div className="contact-icon">
                    <MdPhone />
                  </div>
                  <div className="contact-text">
                    <h4 className="contact-tect-heading">Telephone Number</h4>
                    <p className="text-left">{currLoc[currIndex]?.phone}</p>
                  </div>
                </li>
                <li className="contact-lists">
                  <div className="contact-icon">
                    <FaRegEnvelope />
                  </div>
                  <div className="contact-text">
                    <h4 className="contact-tect-heading">Mail Address</h4>
                    <p className="text-left">{currLoc[currIndex]?.mail}</p>
                  </div>
                </li>
              </ul>
              <div className="my-2">
                <div className="row">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14010.762569808732!2d77.22260515319263!3d28.609056060837446!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce2daa9eb4d0b%3A0x717971125923e5d!2sIndia%20Gate!5e0!3m2!1sen!2sin!4v1714801091228!5m2!1sen!2sin"
                    title="Office Locations"
                    width="100%"
                    height="450"
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;

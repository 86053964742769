import React, { useState, useEffect, useRef } from "react";
import AOS from "aos";
import { publicRequest } from "../../url";
import Files from "react-files";
import uploads from "../../assets/images/uploads.png";

const ContactForm = () => {
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
    if (mounted.current) {
      AOS.init({
        duration: 50
      });
      AOS.refresh();
    }
    return () => (mounted.current = false);
  }, []);

  const initialValues = {
    name: "",
    number: "",
    email: "",
    business: "",
    country: "",
    address: "",
    linkedin: "",
    message: ""
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [files, setFiles] = useState({});
  const [src, setSrc] = useState();

  const onFilesChange = (files) => {
    console.log(files, "lolp");
    setFiles(files[0]);
    const allimg = files.map((item) => URL.createObjectURL(item));
    setSrc(allimg);
  };

  const onFilesError = (error, file) => {
    console.log("error code " + error.code + ": " + error.message);
  };

  const handleChange = (e) => {
    const { name, value } = e.target; // take name and value from input in destructring
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = async (e) => {
    console.log("data set");
    e.preventDefault();
    const errorCheck = validate(formValues);
    setFormErrors(errorCheck);
    setIsSubmit(true);
    if (Object.keys(errorCheck).length === 0) {
      successCheck();
      // console.log(formValues, 'form values data')
    }
  };

  const successCheck = async () => {
    try {
      const formData = new FormData();
      formData.append("docs", files);
      formData.append("name", formValues.name);
      formData.append("number", formValues.number);
      formData.append("email", formValues.email);
      formData.append("business", formValues.business);
      formData.append("country", formValues.country);
      formData.append("address", formValues.address);
      formData.append("linkedin", formValues.linkedin);
      formData.append("message", formValues.message);

      const res = await publicRequest.post("/contact/newContact", formData);
      if (res.status === 200) {
        console.log(res, "res data");
        alert(res.data.message);
        resetInput();
      }
    } catch (err) {
      console.log(err, "err");
      alert(err.response.data.err);
    }
  };

  const resetInput = () => {
    setFormValues(initialValues);
    window.location.reload(false);
  };

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    //name
    if (!/^[a-z A-Z0-9]+$/.test(values.name) || /^[ ]+$/.test(values.name)) {
      errors.name = "Name is not valid";
    }

    if (!values.name) {
      errors.name = "Name is Required";
    }

    if (values.number) {
      if (values.number.length < 10) {
        errors.number = "Enter atleast 10 digits";
      }
    }

    if (!values.number) {
      errors.number = "Phone Number is Required";
    }

    //email
    if (!values.email) {
      errors.email = "Email is Required";
    }
    if (!regex.test(values.email) && values.email) {
      errors.email = "Email is not valid";
    }

    //message
    if (!values.message) {
      errors.message = "Message is Required";
    }

    //rating
    if (!values.linkedin) {
      errors.linkedin = "Please Provide Profile Url";
    }

    //rating
    if (!values.country) {
      errors.country = "Please Input Country";
    }
    return errors;
  };

  return (
    <>
      <div className="contact-auth-section">
        <div className="contact-authentication">
          <h2 className="text-center mb-4">Send Us a Message</h2>
          <form method="POST">
            <div className="form-row row">
              <div className="form-group col-md-6 mb-2">
                <label>
                  Name<span className="text-red font-weight-bold">**</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Your Name"
                  name="name"
                  onChange={handleChange}
                  value={formValues.name}
                />
                <small className="text-red">{formErrors.name}</small>
              </div>
              <div className="form-group col-md-6 mb-2">
                <label>
                  Contact<span className="text-red text-bold">**</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Mobile Number"
                  name="number"
                  value={formValues.number}
                  onChange={handleChange}
                />
                <small className="text-red">{formErrors.number}</small>
              </div>
              <div className="form-group col-md-6 mb-2">
                <label>
                  Email<span className="text-red text-bold">**</span>
                </label>
                <input
                  type="email"
                  name="email"
                  className="form-control"
                  placeholder="Your Email"
                  value={formValues.email}
                  onChange={handleChange}
                />
                <small className="text-red">{formErrors.email}</small>
              </div>

              <div className="form-group col-md-6 mb-2">
                <label>Select Business</label>
                <select
                  name="business"
                  className="form-control"
                  value={formValues?.business}
                  onChange={handleChange}
                >
                  <option value="">Select Interested Business</option>
                  <option value="Asset Management">Asset Management</option>
                  <option value="Credit Solution">Credit Solution</option>
                  <option value="Investment Banking">Investment Banking</option>
                  <option value="Wealth Management">Wealth Management</option>
                </select>
                <small className="text-red">{formErrors.business}</small>
              </div>

              <div className="form-group col-md-6 mb-2">
                <label>Country</label>
                <input
                  type="text"
                  name="country"
                  className="form-control"
                  placeholder="Country"
                  value={formValues.country}
                  onChange={handleChange}
                />
                <small className="text-red">{formErrors.country}</small>
              </div>
              <div className="form-group col-md-6 mb-2">
                <label>Address</label>
                <input
                  type="text"
                  name="address"
                  className="form-control"
                  placeholder="Your Address"
                  value={formValues.address}
                  onChange={handleChange}
                />
                <small className="text-red">{formErrors.address}</small>
              </div>

              <div className="form-group col-12 mb-2">
                <label>Linkedin Profile</label>
                <input
                  type="text"
                  name="linkedin"
                  className="form-control"
                  placeholder="Linkedin Profile"
                  value={formValues.linkedin}
                  onChange={handleChange}
                />
                <small className="text-red">{formErrors.linkedin}</small>
              </div>

              <div className="form-group col-12 mb-3">
                <label>Your Message</label>
                <textarea
                  row="5"
                  className="form-control"
                  placeholder="Your Message..."
                  name="message"
                  value={formValues.message}
                  onChange={handleChange}
                />
                <small className="text-red">{formErrors.message}</small>
              </div>
              <div className="form-group col-12 mb-1">
                {/* <label>Attachment (Optional)</label> */}
                {/* <input
                  type="file"
                  name="file"
                  // className="form-control"
                  placeholder="Upload here"
                  value={formValues.email}
                  onChange={handleChange}
                /> */}

                {!files?.id && (
                  <>
                    <h5>Upload File</h5>
                    <Files
                      className="files-dropzone"
                      onChange={onFilesChange}
                      onError={onFilesError}
                      accepts={[
                        "image/png",
                        "image/jpg",
                        "image/jpeg",
                        "image/webp",
                        ".pdf"
                      ]}
                      multiple
                      maxFileSize={50000000}
                      minFileSize={0}
                      clickable
                      name="files"
                    >
                      <img src={uploads} height="220" width={"100%"} />
                    </Files>
                  </>
                )}
                {files?.id && (
                  <>
                    <h5>File Uploaded</h5>
                    <Files
                      className="files-dropzone"
                      onChange={onFilesChange}
                      onError={onFilesError}
                      accepts={[
                        "image/png",
                        "image/jpg",
                        "image/jpeg",
                        "image/webp",
                        ".pdf"
                      ]}
                      multiple
                      maxFileSize={50000000}
                      minFileSize={0}
                      clickable
                    >
                      <img src={src} height="220" width={"100%"} />
                    </Files>
                  </>
                )}
              </div>
              <div className="form-group col-12">
                <button
                  type="submit"
                  className="submit-btn"
                  onClick={handleSubmit}
                >
                  SUBMIT
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ContactForm;

import React from "react";
import PageHeading from "../components/PageHeading";
import jobopp from "../assets/images/career/jobopp.webp";
import joinus from "../assets/images/career/joinus.png";

const Career = () => {
  return (
    <>
      <section className="career-page-section page-header">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <PageHeading heading={"Join Us"} subheading={"Careers"} />
            </div>
          </div>
        </div>
      </section>

      <section className="career-inner">
        <div className="container">
          <div className="row">
            <div className="col-md-7 col-12">
              <div className="job-opp">
                <h3>Job Opportunities</h3>
                <p>
                  At X-B4, we are always on the lookout for talented individuals
                  who are passionate about Investment Banking & Corporate
                  finance. Our team consists of diverse professionals who
                  collaborate to drive innovation and achieve our goals.
                </p>
              </div>
            </div>
            <div className="col-md-5 col-12">
              <div className="img-box">
                <img
                  src={jobopp}
                  className="img-fluid"
                  alt="job opp"
                  width={275}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="joinus">
        <div className="container">
          <div className="row mb-5">
            <div className="col-12">
              <div className="sec-heading">
                <h2>why join us</h2>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-lg-7 col-12">
              <div className="data-boxes">
                <div className="content-box">
                  <h1>
                    <span>01.</span>Professional Growth
                  </h1>
                  <p>
                    We believe in investing in our employees' growth and
                    development through training programs, mentorship, and
                    opportunities for advancement.
                  </p>
                </div>
                <div className="content-box">
                  <h1>
                    <span>02.</span>Collaborative Team
                  </h1>
                  <p>
                    Our teams work collaboratively to tackle challenges and
                    achieve success together.
                  </p>
                </div>
                <div className="content-box box3">
                  <h1>
                    <span>03.</span>Quality Team
                  </h1>
                  <p>
                    Joining X-B4 means contributing to projects that make a
                    difference and have a positive impact on our customers and
                    communities.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-12">
              <div className="img-box">
                <img src={joinus} className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="process">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="sec-heading mb-3">
                <h2 style={{ color: "#0D0C0A" }}>Application process</h2>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="process-content">
                <div className="steps step-1">
                  <div className="step-para">
                    <p className="stepno">Join Us</p>
                    {/* <p className="step-heading">
                      We are excited to invite you to join our dynamic team. If
                      you are interested in pursuing this opportunity, please
                      attach your resume and cover letter and send them to our
                      HR department at hr@bx-4.com. We look forward to reviewing
                      your application and exploring how your skills and
                      experiences align with our organization's goals.
                    </p> */}
                    <ul>
                      <li>
                      We are excited to invite you to join our dynamic team. If you are interested in pursuing this opportunity, please attach your resume and cover letter and send them to our HR department at <b>hr@bx-4.com</b>. 
                      </li>
                      <li>
                      We look forward to reviewing your application and exploring how your skills and experiences align with our organization's goals.
                      </li>
                      {/* <li>
                        <b>Direct Application:</b> If you have compelling deal
                        sheets, you can apply directly by submitting your deal
                        sheets along with a Letter of Recommendation from top
                        management to our hiring team.
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="process">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="sec-heading">
                <h2 style={{ color: "#0D0C0A" }}>Application process</h2>
                <p style={{ color: "#0D0C0A", textAlign: "center" }}>
                  At X-B4, we primarily hire candidates through referrals, as we
                  value recommendations from trusted sources.
                </p>
                <p style={{ color: "#0D0C0A", textAlign: "center" }}>
                  Additionally, individuals with compelling deal sheets can also
                  apply directly, accompanied by a Letter of Recommendation from
                  top management. Here's our unified application process:
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-12">

                  <div className="process-content">
                    <div className="steps step-1">
                      <div className="step-para">
                        <p className="stepno">Step 01</p>
                        <p className="step-heading">
                          Referral Submission or Direct Application
                        </p>
                        <ul>
                          <li>
                            <b>Referral Submission:</b> Seek referrals from
                            current X-B4 employees or trusted contacts within
                            the industry. Your referrer should submit your
                            resume and a brief overview of why they believe
                            you'd be a great fit for X-B4 directly to our hiring
                            team.
                          </li>
                          <li>
                            <b>Direct Application:</b> If you have compelling
                            deal sheets, you can apply directly by submitting
                            your deal sheets along with a Letter of
                            Recommendation from top management to our hiring
                            team.
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="steps step-2">
                      <div className="step-para">
                        <p className="stepno">Step 02</p>
                        <p className="step-heading">Review and Assessment</p>
                        <ul>
                          <li>
                            Our hiring team will carefully review all
                            submissions, whether through referrals or direct
                            applications, and assess candidates based on their
                            qualifications, experience, and alignment with our
                            company culture and values.
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="steps step-3">
                      <div className="step-para">
                        <p className="stepno">Step 03</p>
                        <p className="step-heading">Interview Process</p>
                        <ul>
                          <li>
                            If your submission meets our criteria, we'll reach
                            out to schedule interviews. This may include phone
                            interviews, video calls, and in-person meetings to
                            further evaluate your fit for the role and our team.
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="steps step-4">
                      <div className="step-para">
                        <p className="stepno">Step 04</p>
                        <p className="step-heading">Offer</p>
                        <ul>
                          <li>
                            If selected, you'll receive an offer to join our
                            team! We'll discuss details such as salary,
                            benefits, and start date.
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="steps step-5">
                      <div className="step-para">
                        <p className="stepno">Step 05</p>
                        <p className="step-heading">Welcome to X-B4</p>
                        <ul>
                          <li>
                            Once you accept our offer, you'll become part of the
                            X-B4 family. We'll provide you with onboarding
                            resources and support to ensure a smooth transition
                            into your new role.
                          </li>
                        </ul>
                      </div>
                    </div>
                    <p className="text-center">
                      Thank you for considering a career with X-B4. Whether
                      through referrals or direct applications, we look forward
                      to receiving your submissions and welcoming you to our
                      team!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
};

export default Career;

import investorPres from '../images/investors/investor-presentation.png';
import earningPres from '../images/investors/earning-presentation.png';
import faceSheet from '../images/investors/fact-sheet.png';
import summarySheet from '../images/investors/summary-sheet.png';
import transsript from '../images/investors/transcript-concall.png';
import annualRep from '../images/investors/annual-reports.png';
import research from '../images/investors/research.png';

export const investorType = [
    {
        id: 1,
        name: "Investor Presentation",
        img: investorPres,
        url: 'investor-presentation',
    },
    {
        id: 2,
        name: "Earning Presentation",
        img: earningPres,
        url: 'earning-presentation',
    },
    {
        id: 3,
        name: "Fact Sheet",
        img: faceSheet,
        url: "fact-sheet",
    },
    {
        id: 4,
        name: "Summary Sheet",
        img: summarySheet,
        url: "summary-sheet",
    },
    {
        id: 5,
        name: "Transcript & Concalls",
        img: transsript,
        url: "transcript-&-concalls",
    },
    {
        id: 6,
        name: "Annual Reports",
        img: annualRep,
        url: "annual-reports",
    },
    {
        id: 7,
        name: "Research",
        img: research,
        url: "research",
    }
]
import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

import { MdCorporateFare } from "react-icons/md";

const Timeline = () => {
  return (
    <>
      <VerticalTimeline>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "#fff", color: "#050926" }}
          contentArrowStyle={{ borderRight: "7px solid  #fff" }}
          // date="2021"
          iconStyle={{ background: "#E36006", color: "#fff" }}
          icon={<MdCorporateFare />}
        >
          <h3 className="vertical-timeline-element-title">2021</h3>
          <h5
            style={{
              color: "#E36006",
              fontWeight: "900"
            }}
            className="vertical-timeline-element-subtitle"
          >
            Two Ex- Big 4 Team Members begin X-B4
          </h5>
          <p
            style={{
              color: "#050926",
              fontSize: "1.1rem",
              lineHeight: "1.75rem",
              fontFamily: "$secondary-font",
              fontWeight: "500"
            }}
          >
            In 2021, two former Big 4 consultants founded X-B4, leveraging their
            extensive industry expertise & global experience in business
            strategy, finance, structuring and syndication.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          // date="2022"
          contentStyle={{ background: "#fff", color: "#050926" }}
          contentArrowStyle={{ borderRight: "7px solid  #fff" }}
          iconStyle={{ background: "#E36006", color: "#fff" }}
          icon={<MdCorporateFare />}
        >
          <h3 className="vertical-timeline-element-title">2022</h3>
          <h5
            style={{
              color: "#E36006",
              fontWeight: "900"
            }}
            className="vertical-timeline-element-subtitle"
          >
            X-B4 begins operations in Mumbai
          </h5>
          <p
            style={{
              color: "#050926",
              fontSize: "1.1rem",
              lineHeight: "1.75rem",
              fontFamily: "$secondary-font",
              fontWeight: "500"
            }}
          >
            X-B4 has commenced operations in Mumbai, expanding its services and
            enhancing connectivity in two of India's major metropolitan areas.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          // date="2023"
          contentStyle={{ background: "#fff", color: "#050926" }}
          contentArrowStyle={{ borderRight: "7px solid  #fff" }}
          iconStyle={{ background: "#E36006", color: "#fff" }}
          icon={<MdCorporateFare />}
        >
          <h3 className="vertical-timeline-element-title">2023</h3>
          <h5
            style={{
              color: "#E36006",
              fontWeight: "900"
            }}
            className="vertical-timeline-element-subtitle"
          >
            X-B4 opens in London
          </h5>
          <p
            style={{
              color: "#050926",
              fontSize: "1.1rem",
              lineHeight: "1.75rem",
              fontFamily: "$secondary-font",
              fontWeight: "500"
            }}
          >
            {" "}
            X-B4 has launched its investment banking services in London,
            offering financial advisory, capital raising, and M&A support to
            businesses and investors in the region.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          // date="2024"
          contentStyle={{ background: "#fff", color: "#050926" }}
          contentArrowStyle={{ borderRight: "7px solid  #fff" }}
          iconStyle={{ background: "#E36006", color: "#fff" }}
          icon={<MdCorporateFare />}
        >
          <h3 className="vertical-timeline-element-title">2024</h3>
          <h5
            style={{
              color: "#E36006",
              fontWeight: "900"
            }}
            className="vertical-timeline-element-subtitle"
          >
            X-B4 opens in Singapore and Dubai
          </h5>
          <p
            style={{
              color: "#050926",
              fontSize: "1.1rem",
              lineHeight: "1.75rem",
              fontFamily: "$secondary-font",
              fontWeight: "500"
            }}
          >
            X-B4, an investment banking firm, expands its global presence by
            opening new offices in Singapore and Dubai, enhancing its
            international service offerings.
          </p>
        </VerticalTimelineElement>
      </VerticalTimeline>
    </>
  );
};

export default Timeline;

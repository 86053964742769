import React from "react";
import { services } from "../assets/data/services";
import { Link } from "react-router-dom";

const ServiceComp = () => {
  return (
    <>
      <section className="service-comp">
        <div className="container">
          <div className="row mb-5">
            <div className="col-12">
              <div className="section-heading">
                <h2>Our Services</h2>
              </div>
            </div>
          </div>
          <div className="row">
            {services.map((data, index) => {
              return (
                <div className="col-lg-4 col-md-6 col-12 my-2">
                  <div className="service-box">
                    <div className="service-box-icon">
                      {data.img}
                    </div>
                    <div className="service-box-content">
                      <h4>
                        <Link to="/">{data.name}</Link>
                      </h4>
                      <p>
                      {data.text}
                      </p>

                      <Link to={`/services/${data.url}`} className="explore-btn">
                        Explore more
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          {/* <div className="row">
            <div className="col-lg-4 col-md-6 col-12 my-2">
              <div className="service-box">
                <div className="service-box-icon">
                  <RiSecurePaymentLine className="service-icon" />
                </div>
                <div className="service-box-content">
                  <h4>
                    <Link to="/">Transaction Advisory</Link>
                  </h4>
                  <p>
                    Navigate the complexities of corporate finance with our
                    expert guidance. From M&A strategies to debt restructuring,
                    we offer tailored solutions to meet your unique needs.
                  </p>

                  <Link to="/" className="explore-btn">
                    Explore more
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 my-2">
              <div className="service-box">
                <div className="service-box-icon">
                  <GoGraph className="service-icon" />
                </div>
                <div className="service-box-content">
                  <h4>
                    <Link to="/">Capital Market Solution</Link>
                  </h4>
                  <p>
                    Enhance shareholder value and expand your reach with our
                    capital market expertise. Whether it's an IPO, private
                    placement, or regulatory compliance, we help you achieve
                    your goals.
                  </p>

                  <Link to="/" className="explore-btn">
                    Explore more
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 my-2">
              <div className="service-box">
                <div className="service-box-icon">
                  <MdCorporateFare className="service-icon" />
                </div>
                <div className="service-box-content">
                  <h4>
                    <Link to="/">Transaction Structuring</Link>
                  </h4>
                  <p>
                    Transaction Structuring Optimize tax efficiency and
                    regulatory compliance with our sophisticated structuring
                    solutions. Our team combines in-depth knowledge with
                    innovative strategies to maximize deal value.
                  </p>

                  <Link to="/" className="explore-btn">
                    Explore more
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 my-2">
              <div className="service-box">
                <div className="service-box-icon">
                  <FaUsersViewfinder className="service-icon" />
                </div>
                <div className="service-box-content">
                  <h4>
                    <Link to="/">Debt Syndication and Advisory</Link>
                  </h4>
                  <p>
                  Access tailored financing solutions to fuel your growth ambitions. From structured debt to cross-border projects funding, we offer comprehensive advisory services to meet your capital needs.

                  </p>

                  <Link to="/" className="explore-btn">
                    Explore more
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 my-2">
              <div className="service-box">
                <div className="service-box-icon">
                  <TbCirclesRelation className="service-icon" />
                </div>
                <div className="service-box-content">
                  <h4>
                    <Link to="/">Investor Relations</Link>
                  </h4>
                  <p>
                  Access tailored financing solutions to fuel your growth ambitions. From structured debt to cross-border projects funding, we offer comprehensive advisory services to meet your capital needs.

                  </p>

                  <Link to="/" className="explore-btn">
                    Explore more
                  </Link>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </section>
    </>
  );
};

export default ServiceComp;

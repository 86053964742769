import React from "react";
import { Link } from "react-router-dom";
import { sectors } from "../assets/data/sector";


const Sectors = ({total, btn}) => {

  console.log(total, 'total')

  return (
    <>
      <section className="globals key-sectors">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="sec-heading">
                <h2>Specialisation in Key Sectors</h2>
                <p>
                  At X-B4, we specialise in providing tailored financial
                  services to various sectors, ensuring that our clients receive
                  strategic guidance and solutions tailored to their specific
                  industry needs. Our expertise extends across the following
                  sectors:
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            {sectors.slice(0,total).map((data, index) => {
              return (
                <div className="col-lg-3 col-md-4 col-sm-6 col-12 my-2">
                  <div className="img-div">
                    <img src={data.img} className="img-fluid" />
                    <figcaption class="img_caption">{data.name}</figcaption>
                  </div>
                </div>
              );
            })}
          </div>

          {/* {
            btn ? (
                <div className="row mt-5">
                <div className="col-12">
                    <div className="team-btn">
                        <button><Link to="/services">Explore more</Link></button>
                    </div>
                </div>
              </div>
            ) : (
                ''
            )
          } */}
        </div>
      </section>
    </>
  );
};

export default Sectors;

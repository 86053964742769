import { userRequest, publicRequest } from "../url";

import {
  getClientStart,
  getClientSuccess,
  getClientFailure
} from "../redux/clientRedux";

export const getClients = async (dispatch) => {
  dispatch(getClientStart());
  try {
    const res = await publicRequest.get("/investors/getAll");
    dispatch(getClientSuccess(res.data));
  } catch (err) {
    dispatch(getClientFailure());
  }
};

import React from "react";
import PageHeading from "../components/PageHeading";
import Investors from "../components/Investors";

const InvestorPage = () => {

  return (
    <>
      <section className="investor-sec page-header">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <PageHeading heading={"Our Clientele"} subheading={"Clientele"} />
            </div>
          </div>
        </div>
      </section>

    <Investors total={4}/>


    </>
  );
};

export default InvestorPage;
